import axios from "axios";
import qs from "qs";
import { message } from "antd";
import cookie from "react-cookies";
// axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
const instance = axios.create({
  baseURL: process.env.BASE_API, // 该处url会根据开发环境进行变化（开发/发布）
  timeout: 10000, // 设置请求超时连接时间
  headers: {
    "content-type": "application/json;charset=UTF-8",
  },
});
// instance.headers['Content-Type'] = 'application/json;charset=utf-8';
const pending = {};
const CancelToken = axios.CancelToken;
const removePending = (key, isRequest = false) => {
  if (pending[key] && isRequest) {
    pending[key]("取消重复请求");
  }
  delete pending[key];
};
const getRequestIdentify = (config, isReuest = false) => {
  let url = config.url;
  if (isReuest) {
    url = config.baseURL + config.url.substring(1, config.url.length);
  }
  // console.log('config',config)
  // console.log(encodeURIComponent(url + JSON.stringify(config.params)))
  return config.method === "get" || config.method === "GET"
    ? encodeURIComponent(url + JSON.stringify(config.params))
    : encodeURIComponent(config.url + JSON.stringify(config.data));
};

// 响应拦截
instance.interceptors.response.use(
  function (response) {
    // console.log(response, "response");
    if (response.data.code === 402) {
      console.log("重新登录");
      localStorage.removeItem("userInfo");
      cookie.remove("accessToken");
      cookie.remove("accessToken", { path: "/" });
      cookie.remove("accessToken", { path: "*" });
      cookie.remove("userInfo");
      cookie.remove("userInfo", { path: "/" });
      cookie.remove("userInfo", { path: "*" });
      window.location.href = "/login";
    }
    // if (response.status && response.status === 200 && response.data.status === 'error') {
    //   message.error(response.data.Message);
    //   return;
    // }
    // if (response.data === 200) {
    //   //用户是否登陆超时
    //   // console.log(response.data.code===404)
    //   // console.log(response.data)
    //   if (response.data.Code === 600) {
    //     // cookie.remove('userInfo')
    //     // window.location.href= "/login"
    //   }

    // }
    return response;
  },
  function (error) {
    console.log(error.response, "err");
    // if (error.response.data.Code === 504 || error.response.data.Code === 404) {
    //   // message.error('服务器出错');
    // } else if (error.response.data.Code === 403) {
    //   message.error('权限不足,请联系管理员!');
    // } else {
    // }
    message.error(error?.response?.data?.message);

    return { data: { code: 504, message: "服务器出错" } };
  }
);

//请求拦截
instance.interceptors.request.use(
  function (config) {
    config.timeout = 30000;
    let requestData = getRequestIdentify(config, true);
    removePending(requestData, true);
    config.cancelToken = new CancelToken((c) => {
      pending[requestData] = c;
    });
    // console.log('config请求拦截',config);
    // 该处可以将config打印出来看一下，该部分将发送给后端（server端）
    config.headers.Authorization = "Bearer " + cookie.load("accessToken");
    config.headers["Content-Type"] = "application/json;charset=utf-8";
    return config; // 对config处理完后返回，下一步将向后端发送请求
  },
  function (error) {
    // 对请求失败做处理
    message.error("请求超时");
    return Promise.reject(error);
  }
);

export default instance;
