import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { Route, Redirect, Link, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Table,
  Form,
  Input,
  Switch,
  Checkbox,
  Button,
  Modal,
  Cascader,
  message,
  Slider,
  InputNumber,
  Tag,
  Upload,
  Select,
} from "antd";
import cookie from "react-cookies";
import "./list.less";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moment from "moment";
import qs from "qs";

import { UPLOAD_FILE } from "../../api/common";
import {
  GET_VERSION_MODEL_LIST,
  GET_VERSION_MODEL_DETAIL,
  SET_VERSION_MODEL,
  DEL_VERSION_MODEL,
  GET_VERSION_PRODUCT_LIST,
  GET_VERSION_DOCS_LIST,
} from "../../api/version";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const beforeUpload = (file) => {
  const isLt2M = file.size / 1024 / 1024 < 200;
  if (!isLt2M) {
    message.error("文件大于200MB!");
  }
  return isLt2M;
};

const CreateShopForm = ({
  visible,
  info = {},
  onCreate,
  onCancel,
  product,
  docs,
}) => {
  const [form] = Form.useForm();
  const [formVals, setFormVals] = useState({});
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [type1, setType1] = useState(
    docs
      .filter((item) => item.type == 1)
      .map((item) => ({
        label: item.name,
        value: item.id + "",
        type: item.type,
      }))
  );
  const [type2, setType2] = useState(
    docs
      .filter((item) => item.type == 2)
      .map((item) => ({
        label: item.name,
        value: item.id + "",
        type: item.type,
      }))
  );
  const [type3, setType3] = useState(
    docs
      .filter((item) => item.type == 3)
      .map((item) => ({
        label: item.name,
        value: item.id + "",
        type: item.type,
      }))
  );
  const [type4, setType4] = useState(
    docs
      .filter((item) => item.type == 4)
      .map((item) => ({
        label: item.name,
        value: item.id + "",
        type: item.type,
      }))
  );

  // 文件包
  const [url, setUrl] = useState([]);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        上传
      </div>
    </div>
  );

  useEffect(() => {
    if (visible) {
      console.log(product, docs);
      form.resetFields();

      info = {
        ...info,
      };

      if (info) {
        info.url && setUrl([info.url]);
        // info.docs
        if (info.docs) {
          const docsList = info.docs.split(",");
          info.type1 = docsList.filter(
            (item) =>
              type1.findIndex((f) => f.value == item && f.type == 1) >= 0
          );
          info.type2 = docsList.filter(
            (item) =>
              type2.findIndex((f) => f.value == item && f.type == 2) >= 0
          );
          info.type3 = docsList.filter(
            (item) =>
              type3.findIndex((f) => f.value == item && f.type == 3) >= 0
          );
          info.type4 = docsList.filter(
            (item) =>
              type4.findIndex((f) => f.value == item && f.type == 4) >= 0
          );
          // console.log(type1, type2, type3, type4, docsList);
          // console.log(
          //   docsList.filter((item) => type1.findIndex((f) => f == item) < 0)
          // );
        }

        // console.log(info);

        form.setFieldsValue({
          ...info,
        });
        setFormVals(info);
      }
    }
  }, [visible]);

  return (
    <Modal
      getContainer={false}
      open={visible}
      width={650}
      footer={false}
      okText={"保存"}
      cancelText={"取消"}
      onOk={() => {
        onCreate(form.getFieldsValue());
      }}
      onCancel={onCancel}
      destroyOnClose
      className="modal-createshop"
      centered
    >
      <Row gutter={30}>
        <Col span={24}>
          <div className="title">
            <h1>{info.id ? "编辑" : "创建"}版本型号</h1>
          </div>
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              ...info,
            }}
            name="form_in_modal"
            onValuesChange={(vals, allVals) => {
              console.log(allVals);
              setFormVals(allVals);
            }}
          >
            <div>
              <Form.Item name="id" hidden>
                <Input />
              </Form.Item>
              <Form.Item name="product_id" label="所属产品">
                <Select
                  options={[
                    ...product.map((item) => ({
                      label: item.name,
                      value: item.id,
                    })),
                  ]}
                />
              </Form.Item>
              <Form.Item name="name" label="版本型号名称">
                <Input />
              </Form.Item>
              <Form.Item name="sort" label="型号排序">
                <InputNumber />
              </Form.Item>
              <Form.Item name="docs" label="文档id" hidden>
                <Input />
              </Form.Item>
              <Form.Item name="type1" label="应用">
                <Checkbox.Group options={type1} />
              </Form.Item>
              <Form.Item name="type2" label="客户端">
                <Checkbox.Group options={type2} />
              </Form.Item>
              <Form.Item name="type3" label="驱动">
                <Checkbox.Group options={type3} />
              </Form.Item>
              <Form.Item name="type4" label="文档">
                <Checkbox.Group options={type4} />
              </Form.Item>
            </div>
          </Form>
          <div className="mt-3">
            <Button
              type="primary"
              size="large"
              className="py-3 px-5"
              style={{ height: "auto", lineHeight: "1" }}
              onClick={() => {
                let params = { ...form.getFieldsValue() };
                params.url = url[0];
                params.docs = [];

                if (params?.type1)
                  params.docs = [...params.docs, ...params?.type1];
                if (params?.type2)
                  params.docs = [...params.docs, ...params?.type2];
                if (params?.type3)
                  params.docs = [...params.docs, ...params?.type3];
                if (params?.type4)
                  params.docs = [...params.docs, ...params?.type4];

                params.docs = params.docs.join(",");

                delete params.type1;
                delete params.type2;
                delete params.type3;
                delete params.type4;

                onCreate(params);
              }}
            >
              {info.id ? "编辑" : "创建"}版本型号
            </Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

const List = () => {
  const [filterForm] = Form.useForm();
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNo: 1,
    total: 0,
  });
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [visibleInfo, setVisibleInfo] = useState({});

  const [dataSource, setDataSource] = useState([]);
  const [product, setProduct] = useState([]);
  const [docs, setDocs] = useState([]);
  const { pathname } = useLocation();
  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "版本型号",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "所属产品",
      dataIndex: ["product", "name"],
      key: ["product", "name"],
    },
    {
      title: "排序",
      dataIndex: "sort",
      key: "sort",
    },
    {
      title: "创建时间",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, data) => moment(text).format("YYYY/MM/DD HH:mm:ss"),
    },
    {
      title: "修改时间",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text, data) => moment(text).format("YYYY/MM/DD HH:mm:ss"),
    },
    {
      title: "操作",
      key: "action",
      render: (text, data) => {
        return (
          <div>
            <Button
              type="link"
              size="small"
              onClick={() => {
                setVisibleCreate(true);
                setVisibleInfo({ ...data });
              }}
            >
              编辑
            </Button>
            <span>|</span>
            <Button
              type="link"
              danger
              size="small"
              onClick={() => {
                Modal.confirm({
                  title: "确定要删除该项吗?",
                  icon: <ExclamationCircleOutlined />,
                  content: "删除后将无法寻回，确认吗?",
                  onOk() {
                    onRemove({
                      id: data.id,
                    });
                  },
                  onCancel() {},
                });
              }}
            >
              删除
            </Button>
          </div>
        );
      },
    },
  ];

  const getProduct = async () => {
    const {
      data: { code, data, msg },
    } = await GET_VERSION_PRODUCT_LIST({
      pageNo: 1,
      pageSize: 99999,
    });

    if (200 === code) {
      setProduct(data.resource);
    }
  };

  const getDocs = async () => {
    const {
      data: { code, data, msg },
    } = await GET_VERSION_DOCS_LIST({
      pageNo: 1,
      pageSize: 99999,
    });

    if (200 === code) {
      setDocs(data.resource);
    }
  };

  const onCreate = async (values) => {
    // console.log(values)
    const {
      data: { code, data, msg },
    } = await SET_VERSION_MODEL(values);

    if (code == 200) {
      message.success(msg);
      setVisibleInfo({});
      getList(pagination);
    } else {
      message.error(msg);
    }
    setVisibleCreate(false);
  };
  const onRemove = async (values) => {
    // console.log(values)
    const {
      data: { code, data, msg },
    } = await DEL_VERSION_MODEL(values);
    if (code == 200) {
      message.success("删除成功");
      getList(pagination);
    } else {
      message.error(msg);
    }
    setVisibleCreate(false);
  };

  const getList = async (paginations) => {
    const {
      data: { code, data, msg },
    } = await GET_VERSION_MODEL_LIST({ ...paginations });
    if (code == 200) {
      setDataSource(data.resource);
      setPagination({
        ...pagination,
        total: data.total,
      });
    }
  };
  const paginationChange = (pages) => {
    setPagination({
      ...pagination,
      pageNo: pages.current || 1,
      current: pages.current,
    });
    getList({
      ...pagination,
      pageNo: pages.current || 1,
      current: pages.current,
    });
    // console.log("pageNo: ", pages);
  };

  useEffect(() => {
    getList(pagination);
    getProduct();
    getDocs();
  }, []);

  return (
    <div className="goods-storage">
      <div className="btn-box">
        <Button
          type="primary"
          className="mr-3"
          onClick={() => {
            setVisibleCreate(true);
          }}
        >
          创建版本型号
        </Button>
      </div>
      <div className="table-action"></div>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={{
          pageSize: pagination.pageSize,
          total: pagination.total,
        }}
        onChange={paginationChange}
      />

      {/* 创建 */}
      {visibleCreate && (
        <CreateShopForm
          visible={visibleCreate}
          info={visibleInfo}
          onCreate={onCreate}
          onCancel={() => {
            setVisibleCreate(false);
            setVisibleInfo({});
          }}
          product={product}
          docs={docs}
        />
      )}
    </div>
  );
};

export default List;
