import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Route, Redirect, Link, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Table,
  Form,
  Input,
  Switch,
  Select,
  Checkbox,
  Button,
  Modal,
  Cascader,
  message,
  Slider,
  InputNumber,
  Tag,
  Upload,
} from "antd";
import cookie from "react-cookies";
import "./list.less";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";
import qs from "qs";
import {
  GET_USER_LIST,
  GET_USER_DETAIL,
  SET_USER,
  SET_USER_INTEGRAL,
  SET_USER_BOND,
  SET_USER_BALANCE,
  DEL_USER,
} from "../../api/user";
import { GET_LEVEL_LIST } from "../../api/level";
import { GET_AGENT_LIST } from "../../api/agent";

const CreateShopForm = ({ visible, info = {}, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  const [formVals, setFormVals] = useState({});
  const [level, setLevel] = useState([]);
  const [agent, setAgent] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible) {
      form.resetFields();
      getLevel();
      getAgent();
      if (info) {
        // console.log(info);
        form.setFieldsValue({
          ...info,
        });
        setFormVals(info);
      }
    }
  }, [visible]);

  const getLevel = async () => {
    const {
      data: { code, data, msg },
    } = await GET_LEVEL_LIST({ pageNo: 1, pageSize: 9999 });

    if (code == 200) {
      setLevel(data.resource);
    }
  };

  const getAgent = async () => {
    const {
      data: { code, data, msg },
    } = await GET_AGENT_LIST({ pageNo: 1, pageSize: 9999 });

    if (code == 200) {
      setAgent(data.resource);
    }
  };

  return (
    <Modal
      getContainer={false}
      open={visible}
      width={650}
      footer={false}
      okText={"保存"}
      cancelText={"取消"}
      onOk={() => {
        onCreate(form.getFieldsValue());
      }}
      onCancel={onCancel}
      destroyOnClose
      className="modal-createshop"
      centered
    >
      <Row gutter={30}>
        <Col span={24}>
          <div className="title">
            <h1>{info.id ? "编辑" : "创建"}用户</h1>
          </div>
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              state: info.state == 1 ? true : false,
              ...info,
            }}
            name="form_in_modal"
            onValuesChange={(vals, allVals) => {
              // console.log(allVals)
              setFormVals(allVals);
            }}
          >
            <div>
              <Form.Item name="id" hidden>
                <Input />
              </Form.Item>
              <Form.Item name="mobile" label="手机号">
                <Input />
              </Form.Item>
              <Form.Item name="email" label="邮箱">
                <Input />
              </Form.Item>
              <Form.Item name="account" label="账号">
                <Input />
              </Form.Item>
              <Form.Item name="password" label="密码">
                <Input.Password />
              </Form.Item>
              <Form.Item name="nickname" label="昵称">
                <Input />
              </Form.Item>
              <Form.Item name="vip_number" label="会员卡号">
                <Input />
              </Form.Item>
              <Form.Item name="type" label="用户类型">
                <Select
                  options={[
                    {
                      label: "普通用户",
                      value: "1",
                    },
                    {
                      label: "代理商",
                      value: "2",
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item name="vip_level_id" label="会员级别">
                <Select
                  options={level.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                />
              </Form.Item>
              <Form.Item name="agent_id" label="代理商等级">
                <Select
                  options={agent.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                />
              </Form.Item>
              <Form.Item name="state" label="用户状态">
                <Switch checked={formVals.state == 1 ? true : false} />
              </Form.Item>
            </div>
          </Form>
          <div className="mt-3">
            <Button
              type="primary"
              size="large"
              className="py-3 px-5"
              style={{ height: "auto", lineHeight: "1" }}
              onClick={() => {
                onCreate(form.getFieldsValue());
              }}
            >
              {info.id ? "编辑" : "创建"}用户
            </Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};
const ChangeMoney = ({ visible, info = {}, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  const [formVals, setFormVals] = useState({});

  useEffect(() => {
    if (visible) {
      form.resetFields();
      info.changeType = "1";
      if (info) {
        // console.log(info);
        form.setFieldsValue({
          ...info,
        });
        setFormVals(info);
      }
    }
  }, [visible]);

  return (
    <Modal
      getContainer={false}
      open={visible}
      width={650}
      footer={false}
      okText={"保存"}
      cancelText={"取消"}
      onOk={() => {
        onCreate(form.getFieldsValue());
      }}
      onCancel={onCancel}
      destroyOnClose
      className="modal-createshop"
      centered
    >
      <Row gutter={30}>
        <Col span={24}>
          <div className="title">
            <h1>
              设置用户
              {(formVals.changeType == "1" && "积分") ||
                (formVals.changeType == "2" && "保证金额") ||
                (formVals.changeType == "3" && "奖励余额") ||
                ""}
            </h1>
          </div>
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              ...info,
            }}
            name="form_in_modal"
            onValuesChange={(vals, allVals) => {
              // console.log(allVals)
              setFormVals(allVals);
            }}
          >
            <div>
              <Form.Item name="id" hidden>
                <Input />
              </Form.Item>
              <Form.Item name="changeType" label="修改项">
                <Select
                  options={[
                    {
                      label: "积分",
                      value: "1",
                    },
                    {
                      label: "保证金",
                      value: "2",
                    },
                    {
                      label: "奖励余额",
                      value: "3",
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item name="type" label="修改类型">
                <Select
                  options={[
                    {
                      label: "增加",
                      value: "1",
                    },
                    {
                      label: "减少",
                      value: "2",
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item name="number" label="修改数值">
                <Input />
              </Form.Item>
              <Form.Item name="tips" label="对外说明">
                <Input.TextArea rows={2} />
              </Form.Item>
              <Form.Item name="sys_tips" label="对内说明">
                <Input.TextArea rows={2} />
              </Form.Item>
            </div>
          </Form>
          <div className="mt-3">
            <Button
              type="primary"
              size="large"
              className="py-3 px-5"
              style={{ height: "auto", lineHeight: "1" }}
              onClick={() => {
                onCreate(form.getFieldsValue());
              }}
            >
              保存
            </Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

const List = () => {
  const [filterForm] = Form.useForm();
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNo: 1,
    total: 0,
  });
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [visibleInfo, setVisibleInfo] = useState({});

  const [visibleChange, setVisibleChange] = useState(false);

  const [dataSource, setDataSource] = useState([]);
  const { pathname } = useLocation();
  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "用户昵称",
      dataIndex: "nickname",
      key: "nickname",
    },
    {
      title: "用户类型",
      dataIndex: "type",
      key: "type",
      render: (text, data) =>
        (text == 1 && "普通用户") || (text == 2 && "代理商") || "-",
    },
    {
      title: "会员卡号",
      dataIndex: "vip_number",
      key: "vip_number",
    },
    {
      title: "会员级别",
      dataIndex: ["level", "name"],
      key: "level_id",
    },
    {
      title: "代理商等级",
      dataIndex: ["agent", "name"],
      key: "agent_id",
    },
    {
      title: "积分",
      dataIndex: "integral",
      key: "integral",
    },
    {
      title: "保证金",
      dataIndex: "bond",
      key: "bond",
    },
    {
      title: "奖励余额",
      dataIndex: "balance",
      key: "balance",
    },
    {
      title: "已购买设备授权数量",
      dataIndex: "auth_count",
      key: "auth_count",
      align: 'center',
    },
    {
      title: "状态",
      dataIndex: "state",
      key: "state",
      render: (text, data) =>
        (text == 1 && "正常") || (text == 2 && "禁用") || "-",
    },
    {
      title: "创建时间",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, data) => moment(text).format("YYYY/MM/DD HH:mm:ss"),
    },
    {
      title: "操作",
      key: "action",
      render: (text, data) => {
        return (
          <div>
            <Button
              type="link"
              size="small"
              onClick={() => {
                setVisibleCreate(true);
                setVisibleInfo({ ...data });
              }}
            >
              编辑
            </Button>
            <span>|</span>
            <Button
              type="link"
              size="small"
              onClick={() => {
                setVisibleChange(true);
                setVisibleInfo({ ...data });
              }}
            >
              修改积分/金额
            </Button>
            <span>|</span>
            <Button
              type="link"
              danger
              size="small"
              onClick={() => {
                Modal.confirm({
                  title: "确定要删除该项吗?",
                  icon: <ExclamationCircleOutlined />,
                  content: "删除后将无法寻回，确认吗?",
                  onOk() {
                    onRemove({
                      id: data.id,
                    });
                  },
                  onCancel() {},
                });
              }}
            >
              删除
            </Button>
          </div>
        );
      },
    },
  ];

  const onCreate = async (values) => {
    // console.log(values)

    values.state = values.state ? 1 : 2;

    const {
      data: { code, data, msg },
    } = await SET_USER(values);

    if (code == 200) {
      message.success(msg);
      setVisibleInfo({});
      getList(pagination);
    } else {
      message.error(msg);
    }
    setVisibleCreate(false);
  };

  const onChange = async (values) => {
    // console.log(values)
    values.user_id = values.id;

    let res;

    if (values.changeType == "1") {
      res = await SET_USER_INTEGRAL(values);
    } else if (values.changeType == "2") {
      res = await SET_USER_BOND(values);
    } else if (values.changeType == "3") {
      res = await SET_USER_BALANCE(values);
    } else {
      return;
    }

    delete values.id;
    delete values.changeType;

    const {
      data: { code, data, msg },
    } = res;

    if (code == 200) {
      message.success(msg);
      setVisibleInfo({});
      getList(pagination);
    } else {
      message.error(msg);
    }
    setVisibleChange(false);
  };

  const onRemove = async (values) => {
    // console.log(values)
    const {
      data: { code, data, msg },
    } = await DEL_USER(values);
    if (code == 200) {
      message.success("删除成功");
      getList(pagination);
    } else {
      message.error(msg);
    }
    setVisibleCreate(false);
  };

  const getList = async (paginations) => {
    const {
      data: { code, data, msg },
    } = await GET_USER_LIST({ ...paginations });
    if (code == 200) {
      setDataSource(data.resource);
      setPagination({
        ...pagination,
        total: data.total,
      });
    }
  };
  const paginationChange = (pages) => {
    setPagination({
      ...pagination,
      pageNo: pages.current || 1,
      current: pages.current,
    });
    getList({
      ...pagination,
      pageNo: pages.current || 1,
      current: pages.current,
    });
    // console.log("pageNo: ", pages);
  };

  useEffect(() => {
    getList(pagination);
  }, []);

  return (
    <div className="goods-storage">
      <div className="btn-box">
        <Button
          type="primary"
          className="mr-3"
          onClick={() => {
            setVisibleCreate(true);
          }}
        >
          创建用户
        </Button>
        <span className="ml-3">总用户数：{pagination.total}</span>
      </div>
      <div className="table-action mb-3">
        <Form
          layout="inline"
          onValuesChange={(cVal, vals) => {
            console.log(cVal, vals);
            let where = {
              ...pagination.where,
              ...cVal, 
            };

            setPagination({
              ...pagination,
              where,
            });
            console.log(pagination);
          }}
        >
          <Form.Item label="手机号" name="mobile">
            <Input allowClear />
          </Form.Item>
          <Form.Item label="会员卡号" name="vip_number">
            <Input allowClear />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              onClick={() => {
                setPagination({
                  ...pagination,
                  pageNo: 1,
                  current: 1,
                });
                getList({
                  ...pagination,
                  pageNo: 1,
                  current: 1,
                });
              }}
            >
              搜索
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Table
        rowKey={"id"}
        columns={columns}
        dataSource={dataSource}
        pagination={{
          pageSize: pagination.pageSize,
          total: pagination.total,
        }}
        onChange={paginationChange}
      />

      {/* 创建 */}
      {visibleCreate && (
        <CreateShopForm
          visible={visibleCreate}
          info={visibleInfo}
          onCreate={onCreate}
          onCancel={() => {
            setVisibleCreate(false);
            setVisibleInfo({});
          }}
        />
      )}

      {/* 修改 */}
      {visibleChange && (
        <ChangeMoney
          visible={visibleChange}
          info={visibleInfo}
          onCreate={onChange}
          onCancel={() => {
            setVisibleChange(false);
            setVisibleInfo({});
          }}
        />
      )}
    </div>
  );
};

export default List;
