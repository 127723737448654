import {
  PlusOutlined,
  CloseCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  Table,
  Popover,
  Button,
  Input,
  InputRef,
  message,
  Tag,
  InputNumber,
} from "antd";
import { useRef, useState } from "react";
import { useEffect } from "react";
// import { TableData } from "./interface";
// eslint-disable-next-line react-refresh/only-export-components
export default (props) => {
  const { submitList, setSubmitList, tableData, setTableData } = props;
  // ---------变量------------
  const inputRef = useRef(null);
  const inputRefValue = useRef(null);
  const [focusInput, setFocusInput] = useState(false);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValueIndex, setInputValueIndex] = useState(0);
  const [inputSpecName, setInputSpecName] = useState(""); //规格名
  const [specValue, setSpecValue] = useState(""); //规格值
  const [specList, setSpecList] = useState([]); // 规格展示数据
  const skuValues = useRef({}); // 规格sku
  // ---------方法函数------------
  function onPressEnter() {
    if (inputSpecName) {
      const hasName = specList.findIndex((item) => item.label == inputSpecName);
      if (hasName >= 0) {
        message.error("规格名已存在");
        return false;
      }

      specList.push({ label: inputSpecName, tags: [] });
      setInputSpecName("");
    } else {
      message.error("请输入规格名称");
    }
  }
  //添加规格值
  function onAddSpecValue(label) {
    if (specValue) {
      const hasValue = specList
        .find((t) => t.label === label)
        ?.tags.findIndex((item) => item == specValue);
      if (hasValue >= 0) {
        message.error("规格值已存在");
        setSpecValue("");
        setInputVisible(false);
        return false;
      }

      // 规格值
      specList.find((t) => t.label === label)?.tags.push(specValue);
      // 如果有一样的规格名称就push到老的规格值里面
      if (skuValues.current[label]) {
        skuValues.current[label].push(specValue);
      } else {
        skuValues.current = { ...skuValues.current, [label]: [specValue] };
      }
      tableSKU();
      setSpecValue("");
      setInputVisible(false);
    } else {
      setInputVisible(false);
    }
  }
  // 删除规格值
  function onDeleteSpecValue(index, tagsIndex, label) {
    specList[index].tags.splice(tagsIndex, 1);
    skuValues.current[label].splice(tagsIndex, 1); //找到相对于的那个sku并删除里面值
    tableSKU(); //重新绘制页面和数据
  }
  // 删除规格
  function onDeleteSpec(label) {
    delete skuValues.current[label];
    const filterValue = specList.filter((t) => t.label !== label);
    setSpecList(filterValue);
    tableSKU(); //重新绘制页面和数据
  }
  function tableSKU() {
    let temp = [];
    if (JSON.stringify(skuValues.current) === "{}") setTableData([]);

    for (const key in skuValues.current) {
      let headers = Object.keys(skuValues.current),
        skuItems = [];
      const items = skuValues.current[key];
      if (!temp.length) temp.push(...items.map((t) => ({ [key]: t })));
      else {
        const i2 = [];
        temp.forEach((obj) => {
          if (items.length === 0) i2.push(obj);
          else i2.push(...items.map((t) => ({ ...obj, [key]: t })));
        });
        temp = i2;
      }
      setTableData(temp);
      for (let index = 0; index < temp.length; index++) {
        const el = temp[index];
        let count = 0,
          obj = {
            code: "",
            sale_price: 0,
            current_price: 0,
            cost_price: 0,
            stock: 0,
            lock_stock: 0,
            sale: 0,
          };
        for (let i = 0; i < headers.length; i++) {
          // console.log(headers);
          const hader = headers[i];
          if (hader) {
            count++;
            const oldValue = submitList[index]; //防止输入的数据丢失
            // console.log(hader, oldValue, el, index);
            if (oldValue) {
              obj = {
                ...obj,
                [`skuName${count}`]: hader,
                [`skuValue${count}`]: el[hader],
                code: oldValue.code || "",
                sale_price: oldValue.sale_price || 0,
                current_price: oldValue.current_price || 0,
                cost_price: oldValue.cost_price || 0,
                stock: oldValue.stock || 0,
                lock_stock: oldValue.lock_stock || 0,
                sale: oldValue.sale || 0,
                id: oldValue.id || undefined,
              };
            } else {
              obj = {
                ...obj,
                [`skuName${count}`]: hader,
                [`skuValue${count}`]: el[hader],
                id: index + i,
              };
            }
          }
        }
        skuItems.push({ ...obj });
      }

      setSubmitList(skuItems);
    }
    // console.log(skuItems);

    return temp;
  }

  useEffect(() => {
    // console.log("specList", specList);
    // console.log("skuValues", skuValues);
    // console.log("submitList", submitList);
  }, [submitList]);

  // 修改表格中的数据
  const changeRecordData = (key, index, value) => {
    let submitLists = [...submitList];
    // console.log(submitLists);
    // console.log(key, index, value);
    submitLists[index][key] = value;
    setSubmitList([...submitLists]);
  };

  // 根据record获得下标
  const recordFindIndex = (record) => {
    return submitList.findIndex((item, index) => {
      let spu = Object.values(record),
        values = [],
        vVal = [];
      for (const key in spu) {
        values.push(spu[key]);
        vVal.push(item[`skuValue${Number(key) + 1}`]);
      }

      if (values.join(",") === vVal.join(",")) {
        return index + "";
      }
    });
  };
  // ---------生命周期------------
  useEffect(() => {
    inputRef.current?.focus();
    return () => {
      setFocusInput(false);
    };
  }, [focusInput]);
  useEffect(() => {
    inputRefValue.current?.focus();
  }, [inputVisible]);
  useEffect(() => {
    if (tableData && tableData?.length) {
      tableData.map((item) => {
        const keyNames = Object.keys(item);
        const keyValues = Object.values(item);
        // 判断规格不存在则添加
        for (const key in keyNames) {
          if (specList.findIndex((it) => it.label == keyNames[key]) < 0) {
            specList.push({ label: keyNames[key], tags: [keyValues[key]] });
            skuValues.current[keyNames[key]] = [keyValues[key]];
          }
        }

        // 判断规格值不存在则添加
        for (const vKey in keyValues) {
          let labelIndex = specList.findIndex(
            (it) => it.label == keyNames[vKey]
          );
          if (
            specList[labelIndex].tags.findIndex(
              (vIt) => vIt == keyValues[vKey]
            ) < 0
          ) {
            specList[labelIndex].tags.push(keyValues[vKey]);
            skuValues.current[keyNames[vKey]].push(keyValues[vKey]);
          }
        }
      });

      tableSKU();
      console.log("specList", specList);
      console.log("skuValues", skuValues);
    }
  }, []);
  // ----------HTML-----------
  return (
    <div className="sku-context">
      <div className="actions">
        <Input
          style={{ width: 400 }}
          onPressEnter={onPressEnter}
          onChange={(e) => setInputSpecName(e.target.value)}
          ref={inputRef}
          placeholder="请输入规格名称，请按下回车键或按钮确认"
          value={inputSpecName}
          addonAfter={
            <Button type="primary" onClick={onPressEnter}>
              <PlusOutlined /> 添加规格
            </Button>
          }
        />
      </div>
      <div className="header">
        {specList.map(({ label, tags }, index) => {
          return (
            <div className="spu" key={index}>
              <div className="title">
                <h3>
                  {label}
                  <Button type="link" onClick={() => onDeleteSpec(label)}>
                    删除
                  </Button>
                </h3>
              </div>
              <div className="sku" style={{ display: "flex" }}>
                {tags.map((t, tagsIndex) => (
                  <Tag bordered={false} color="blue" key={tagsIndex}>
                    <span>{t}</span>
                    <CloseCircleOutlined
                      onClick={() => onDeleteSpecValue(index, tagsIndex, label)}
                    />
                  </Tag>
                ))}
                <div>
                  {inputVisible && inputValueIndex === index ? (
                    <Input
                      ref={inputRefValue}
                      value={specValue}
                      onChange={(e) => setSpecValue(e.target.value)}
                      onPressEnter={() => onAddSpecValue(label)}
                      onBlur={() => onAddSpecValue(label)}
                      placeholder="请输入规格值"
                      size="small"
                      addonAfter={
                        <Button
                          size="small"
                          type="primary"
                          onClick={() => onAddSpecValue(label)}
                        >
                          <PlusOutlined /> 添加规格值
                        </Button>
                      }
                    />
                  ) : (
                    <Tag
                      bordered={false}
                      color="#108ee9"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setInputVisible(true);
                        setInputValueIndex(index);
                      }}
                    >
                      <PlusOutlined /> 新增规格值
                    </Tag>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Table
        rowKey={"id"}
        size="small"
        scroll={{
          y: "300px",
        }}
        pagination={false}
        columns={[
          ...specList.map((t, i) => {
            return {
              title: t.label,
              render: (record) => {
                return record[t.label];
              },
            };
          }),
          {
            title: "货号",
            dataIndex: "code",
            key: "code",
            width: 150,
            render: (value, record, index) => {
              // console.log(value, record, index);
              return (
                <Input
                  onChange={(e) => {
                    changeRecordData(
                      "code",
                      recordFindIndex(record),
                      e.target.value
                    );
                  }}
                  value={submitList[recordFindIndex(record)]?.code}
                  style={{ width: "100%" }}
                />
              );
            },
          },
          {
            title: "售价",
            dataIndex: "sale_price",
            key: "sale_price",
            width: 120,
            render: (value, record, index) => {
              return (
                <InputNumber
                  onChange={(e) => {
                    changeRecordData("sale_price", recordFindIndex(record), e);
                  }}
                  value={submitList[recordFindIndex(record)]?.sale_price || 0}
                  precision={2}
                  style={{ width: "100%" }}
                />
              );
            },
          },
          {
            title: "现价",
            dataIndex: "current_price",
            key: "current_price",
            width: 120,
            render: (value, record, index) => {
              return (
                <InputNumber
                  onChange={(e) => {
                    changeRecordData(
                      "current_price",
                      recordFindIndex(record),
                      e
                    );
                  }}
                  value={
                    submitList[recordFindIndex(record)]?.current_price || 0
                  }
                  precision={2}
                  style={{ width: "100%" }}
                />
              );
            },
          },
          {
            title: "成本价",
            dataIndex: "cost_price",
            key: "cost_price",
            width: 120,
            render: (value, record, index) => {
              return (
                <InputNumber
                  onChange={(e) => {
                    changeRecordData("cost_price", recordFindIndex(record), e);
                  }}
                  value={submitList[recordFindIndex(record)]?.cost_price || 0}
                  precision={2}
                  style={{ width: "100%" }}
                />
              );
            },
          },
          {
            title: "库存",
            dataIndex: "stock",
            key: "stock",
            width: 120,
            render: (value, record, index) => {
              return (
                <InputNumber
                  onChange={(e) => {
                    changeRecordData("stock", recordFindIndex(record), e);
                  }}
                  value={submitList[recordFindIndex(record)]?.stock || 0}
                  precision={0}
                  style={{ width: "100%" }}
                />
              );
            },
          },
          {
            title: "锁定库存",
            dataIndex: "lock_stock",
            key: "lock_stock",
            width: 120,
            render: (value, record, index) => {
              return (
                <InputNumber
                  onChange={(e) => {
                    changeRecordData("lock_stock", recordFindIndex(record), e);
                  }}
                  value={submitList[recordFindIndex(record)]?.lock_stock || 0}
                  precision={0}
                  style={{ width: "100%" }}
                />
              );
            },
          },
          {
            title: "销量",
            dataIndex: "sale",
            key: "sale",
            width: 120,
            render: (value, record, index) => {
              return (
                <InputNumber
                  onChange={(e) => {
                    changeRecordData("sale", recordFindIndex(record), e);
                  }}
                  value={submitList[recordFindIndex(record)]?.sale || 0}
                  precision={0}
                  style={{ width: "100%" }}
                />
              );
            },
          },
        ]}
        footer={() => (
          <div className="footer">
            <div className="">
              <span>批量设置：</span>
              <Popover
                content={
                  <Input
                    style={{ width: 300 }}
                    onChange={(e) => {
                      let submitListArr = [...submitList];
                      submitListArr = submitListArr.map((item) => ({
                        ...item,
                        code: e.target.value,
                      }));
                      setSubmitList([...submitListArr]);
                      console.log("submitList: ", submitList);
                    }}
                    placeholder="请输入货号"
                  />
                }
                trigger="click"
                destroyTooltipOnHide
              >
                <Button type="link" className="mr-1">
                  货号
                </Button>
              </Popover>
              <Popover
                content={
                  <InputNumber
                    style={{ width: 300 }}
                    onChange={(e) => {
                      let submitListArr = [...submitList];
                      submitListArr = submitListArr.map((item) => ({
                        ...item,
                        sale_price: e,
                      }));
                      setSubmitList([...submitListArr]);
                      console.log("submitList: ", submitList);
                    }}
                    placeholder="请输入售价"
                    defaultValue={0}
                    precision={2}
                  />
                }
                trigger="click"
                destroyTooltipOnHide
              >
                <Button type="link" className="mr-1">
                  售价
                </Button>
              </Popover>
              <Popover
                content={
                  <InputNumber
                    style={{ width: 300 }}
                    onChange={(e) => {
                      let submitListArr = [...submitList];
                      submitListArr = submitListArr.map((item) => ({
                        ...item,
                        current_price: e,
                      }));
                      setSubmitList([...submitListArr]);
                      console.log("submitList: ", submitList);
                    }}
                    placeholder="请输入现价"
                    defaultValue={0}
                    precision={2}
                  />
                }
                trigger="click"
                destroyTooltipOnHide
              >
                <Button type="link" className="mr-1">
                  现价
                </Button>
              </Popover>
              <Popover
                content={
                  <InputNumber
                    style={{ width: 300 }}
                    onChange={(e) => {
                      let submitListArr = [...submitList];
                      submitListArr = submitListArr.map((item) => ({
                        ...item,
                        cost_price: e,
                      }));
                      setSubmitList([...submitListArr]);
                      console.log("submitList: ", submitList);
                    }}
                    placeholder="请输入成本价"
                    defaultValue={0}
                    precision={2}
                  />
                }
                trigger="click"
                destroyTooltipOnHide
              >
                <Button type="link" className="mr-1">
                  成本价
                </Button>
              </Popover>
              <Popover
                content={
                  <InputNumber
                    style={{ width: 300 }}
                    onChange={(e) => {
                      let submitListArr = [...submitList];
                      submitListArr = submitListArr.map((item) => ({
                        ...item,
                        stock: e,
                      }));
                      setSubmitList([...submitListArr]);
                      console.log("submitList: ", submitList);
                    }}
                    placeholder="请输入库存"
                    defaultValue={0}
                    precision={0}
                  />
                }
                trigger="click"
                destroyTooltipOnHide
              >
                <Button type="link" className="mr-1">
                  库存
                </Button>
              </Popover>
              <Popover
                content={
                  <InputNumber
                    style={{ width: 300 }}
                    onChange={(e) => {
                      let submitListArr = [...submitList];
                      submitListArr = submitListArr.map((item) => ({
                        ...item,
                        lock_stock: e,
                      }));
                      setSubmitList([...submitListArr]);
                      console.log("submitList: ", submitList);
                    }}
                    placeholder="请输入锁定库存"
                    defaultValue={0}
                    precision={0}
                  />
                }
                trigger="click"
                destroyTooltipOnHide
              >
                <Button type="link" className="mr-1">
                  锁定库存
                </Button>
              </Popover>
              <Popover
                content={
                  <InputNumber
                    style={{ width: 300 }}
                    onChange={(e) => {
                      let submitListArr = [...submitList];
                      submitListArr = submitListArr.map((item) => ({
                        ...item,
                        sale: e,
                      }));
                      setSubmitList([...submitListArr]);
                      console.log("submitList: ", submitList);
                    }}
                    placeholder="请输入销量"
                    defaultValue={0}
                    precision={0}
                  />
                }
                trigger="click"
                destroyTooltipOnHide
              >
                <Button type="link" className="mr-1">
                  销量
                </Button>
              </Popover>
            </div>
          </div>
        )}
        dataSource={tableData}
      />
    </div>
  );
};
