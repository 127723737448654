import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
import qs from "qs";

/**
 * 获取用户列表
 * @param {Object} parm 请求参数
 * @param {Number} parm.pageSize 每页数量
 * @param {Number} parm.pageNo 当前页码
 * @returns
 */
export const GET_USER_LIST = async (parm) =>
  await instance.post(`${base}/admin/user/list`, parm);

/**
 * 获取用户详情
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 用户ID
 * @returns
 */
export const GET_USER_DETAIL = async () =>
  await instance.get(`${base}/admin/user/detail`);

/**
 * 设置用户
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 用户ID（修改用）
 * @param {String} parm.mobile 手机号
 * @param {String} parm.email 邮箱
 * @param {String} parm.account 用户名
 * @param {String} parm.password 密码
 * @param {String} parm.nickname 昵称
 * @param {Enum} parm.type 用户类型 1：普通用户 2：代理商
 * @param {Number} parm.vip_number 会员卡号
 * @param {Number} parm.vip_level_id 会员级别ID
 * @param {Number} parm.agent_id 代理商等级ID
 * @param {Number} parm.integral 积分（消费金额）
 * @param {Number} parm.bond 保证金额 （冻结不变，管理端手动变更）
 * @param {Number} parm.balance 奖励余额 （管理端手动变更）
 * @param {Enum} parm.state 用户状态 1：启用 2：禁用
 * @returns
 */
export const SET_USER = async (parm) =>
  await instance.post(`${base}/admin/user/set`, parm);

/**
 * 修改用户积分
 * @param {Object} parm 请求参数
 * @param {Number} parm.user_id 用户ID
 * @param {String} parm.type 修改类型 1:增加 2:减少
 * @param {String} parm.number 修改数值
 * @param {String} parm.tips 备注说明
 * @param {String} parm.sys_tips 系统内部备注说明
 * @returns
 */
export const SET_USER_INTEGRAL = async (parm) =>
  await instance.post(`${base}/admin/user/setIntegral`, parm);

/**
 * 修改用户保证金金额
 * @param {Object} parm 请求参数
 * @param {Number} parm.user_id 用户ID
 * @param {String} parm.type 修改类型 1:增加 2:减少
 * @param {String} parm.number 修改数值
 * @param {String} parm.tips 备注说明
 * @param {String} parm.sys_tips 系统内部备注说明
 * @returns
 */
export const SET_USER_BOND = async (parm) =>
  await instance.post(`${base}/admin/user/setBond`, parm);

/**
 * 修改用户奖励余额
 * @param {Object} parm 请求参数
 * @param {Number} parm.user_id 用户ID
 * @param {String} parm.type 修改类型 1:增加 2:减少
 * @param {String} parm.number 修改数值
 * @param {String} parm.tips 备注说明
 * @param {String} parm.sys_tips 系统内部备注说明
 * @returns
 */
export const SET_USER_BALANCE = async (parm) =>
  await instance.post(`${base}/admin/user/setBalance`, parm);

/**
 * 删除用户
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 等级项ID
 * @returns
 */
export const DEL_USER = async (parm) =>
  await instance.delete(`${base}/admin/user/del`, { data: { ...parm } });
