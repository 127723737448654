import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
import qs from "qs";

/**
 * 获取应用分类列表
 * @param {Object} parm 请求参数
 * @param {Number} parm.pageSize 每页数量
 * @param {Number} parm.pageNo 当前页码
 * @returns
 */
export const GET_APPLICATION_CLASSIFY_LIST = async (parm) =>
  await instance.post(`${base}/admin/application/classify/list`, parm);

/**
 * 获取应用分类详情
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 应用分类ID
 * @returns
 */
export const GET_APPLICATION_CLASSIFY_DETAIL = async () =>
  await instance.get(`${base}/admin/application/classify/detail`);

/**
 * 设置应用分类
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 应用分类ID
 * @param {String} parm.name 应用分类名称
 * @returns
 */
export const SET_APPLICATION_CLASSIFY = async (parm) =>
  await instance.post(`${base}/admin/application/classify/set`, parm);

/**
 * 删除应用分类
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 应用分类ID
 * @returns
 */
export const DEL_APPLICATION_CLASSIFY = async (parm) =>
  await instance.delete(`${base}/admin/application/classify/del`, { data: { ...parm } });
