import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Route, Redirect, Link, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Table,
  Form,
  Input,
  Switch,
  Checkbox,
  Button,
  Modal,
  Cascader,
  message,
  Slider,
  InputNumber,
  Tag,
  Upload,
  Select,
} from "antd";
import cookie from "react-cookies";
import "./list.less";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import moment from "moment";
import qs from "qs";
import { UPLOAD_FILE } from "../../api/common";
import { GET_APPLICATION_TYPE_LIST } from "../../api/applicationType";
import { GET_APPLICATION_CLASSIFY_LIST } from "../../api/applicationClassify";
import {
  GET_APPLICATION_LIST,
  GET_APPLICATION_DETAIL,
  SET_APPLICATION,
  DEL_APPLICATION,
} from "../../api/application";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const beforeUpload = (file) => {
  const isLt2M = file.size / 1024 / 1024 < 200;
  if (!isLt2M) {
    message.error("文件大于200MB!");
  }
  return isLt2M;
};

const CreateShopForm = ({ visible, info = {}, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  const [formVals, setFormVals] = useState({});
  const [type, setType] = useState([]);
  const [classify, setClassify] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  // 图标
  const [iconFile, setIconFile] = useState([]);
  // 大图
  const [images, setImages] = useState([]);
  // 安装脚本
  const [install, setInstall] = useState([]);
  // 卸载脚本
  const [uninstall, setUninstall] = useState([]);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        上传
      </div>
    </div>
  );

  useEffect(() => {
    if (visible) {
      getTypes();
      getClassify();
      form.resetFields();

      info = {
        is_selected: false,
        state: true,
        ...info,
      };

      if (info) {
        info.icon && setIconFile([info.icon]);
        info.images &&
          setImages([
            ...info.images?.split(",")?.map((item, ind) => ({
              uid: ind,
              name: item,
              status: "done",
              url: item,
              thumbUrl: item,
            })),
          ]);
        info.install_job && setInstall([info.install_job]);
        form.setFieldsValue({
          ...info,
        });
        setFormVals({ ...info });
      }
    }
  }, [visible]);

  const getTypes = async () => {
    const {
      data: { data, code, msg },
    } = await GET_APPLICATION_TYPE_LIST({
      pageSize: 9999,
      pageNo: 1,
    });

    if (code == 200) {
      setType(data.resource);
    }
  };

  const getClassify = async () => {
    const {
      data: { data, code, msg },
    } = await GET_APPLICATION_CLASSIFY_LIST({
      pageSize: 9999,
      pageNo: 1,
    });

    if (code == 200) {
      setClassify(data.resource);
    }
  };

  // 自定义上传图片
  const customRequest = async (params, image, setImage, ismore) => {
    setLoading(true);
    const { file, onSuccess, onError } = params;
    const formData = new FormData();
    formData.append("file", file);

    const {
      data: { data, msg, code },
    } = await UPLOAD_FILE(formData);

    if (code == 200) {
      setImage && setImage((ismore && [data]) || [...image, data]);
    }
    console.log(data);
    setLoading(false);
  };

  return (
    <Modal
      getContainer={false}
      open={visible}
      width={650}
      footer={false}
      okText={"保存"}
      cancelText={"取消"}
      onOk={() => {}}
      onCancel={onCancel}
      destroyOnClose
      className="modal-createshop"
      centered
    >
      <Row gutter={30}>
        <Col span={24}>
          <div className="title">
            <h1>{info.id ? "编辑" : "创建"}应用</h1>
          </div>
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              ...info,
            }}
            name="form_in_modal"
            onValuesChange={(vals, allVals) => {
              // console.log(allVals)
              setFormVals(allVals);
            }}
          >
            <div>
              <Form.Item name="id" hidden>
                <Input />
              </Form.Item>
              <Form.Item name="name" label="应用名称">
                <Input />
              </Form.Item>
              <Form.Item name="author" label="开发者">
                <Input />
              </Form.Item>
              <Form.Item name="icon" label="图标">
                <Upload
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  customRequest={async (params) => {
                    setLoading(true);
                    const { file, onSuccess, onError } = params;
                    const formData = new FormData();
                    formData.append("file", file);

                    const {
                      data: { data, msg, code },
                    } = await UPLOAD_FILE(formData);

                    if (code == 200) {
                      setIconFile([data]);
                    }
                    console.log(data);
                    setLoading(false);
                  }}
                  beforeUpload={beforeUpload}
                >
                  {iconFile[0] ? (
                    <img
                      src={iconFile[0]}
                      alt="avatar"
                      style={{
                        width: "100%",
                      }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>
              <Form.Item name="images" label="大图">
                <Upload
                  listType="picture"
                  fileList={[...images]}
                  customRequest={async (params) => {
                    const { file, onSuccess, onError } = params;
                    const formData = new FormData();
                    formData.append("file", file);

                    const {
                      data: { data, msg, code },
                    } = await UPLOAD_FILE(formData);

                    if (code == 200) {
                      setImages([
                        ...images,
                        {
                          uid: images.length,
                          name: data,
                          status: "done",
                          url: data,
                          thumbUrl: data,
                        },
                      ]);
                    }
                    console.log(data);
                  }}
                  onRemove={(file) => {
                    setImages([
                      ...images.filter((item) => item.uid != file.uid),
                    ]);
                  }}
                  beforeUpload={beforeUpload}
                >
                  <Button icon={<UploadOutlined />}>上传</Button>
                </Upload>
              </Form.Item>
              <Form.Item name="intro" label="描述">
                <Input.TextArea rows={3} />
              </Form.Item>
              <Form.Item name="is_selected" label="是否精选">
                <Switch checked={formVals.is_selected} />
              </Form.Item>
              <Form.Item name="classify_id" label="应用分类">
                <Select
                  options={classify.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                />
              </Form.Item>
              <Form.Item name="type_id" label="应用类型">
                <Select
                  options={type.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                />
              </Form.Item>
              <Form.Item name="version" label="版本">
                <Input />
              </Form.Item>
              {/* <Form.Item name="port_map" label="端口地图">
                <Upload />
              </Form.Item> */}
              <Form.Item name="index" label="首页地址">
                <Input />
              </Form.Item>
              <Form.Item name="install_job" label="YML文件">
                <Upload
                  listType="picture"
                  maxCount={1}
                  className="avatar-uploader"
                  showUploadList={false}
                  customRequest={async (params) => {
                    setLoading(true);
                    const { file, onSuccess, onError } = params;
                    const formData = new FormData();
                    formData.append("file", file);

                    const {
                      data: { data, msg, code },
                    } = await UPLOAD_FILE(formData);

                    if (code == 200) {
                      setInstall([data]);
                    }
                    // console.log(data);
                    setLoading(false);
                  }}
                  beforeUpload={beforeUpload}
                >
                  {install[0] ? (
                    <div
                      className="file-box"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src={
                          require("../../assets/image/file-b-17.png").default
                        }
                        alt="file"
                        style={{
                          width: "80px",
                          marginRight: "15px",
                        }}
                      />
                      <div style={{ color: "#999" }}>{install[0]}</div>
                    </div>
                  ) : (
                    <Button icon={<UploadOutlined />}>上传</Button>
                  )}
                </Upload>
              </Form.Item>
              <Form.Item name="state" label="状态">
                <Switch checked={formVals.state} />
              </Form.Item>
            </div>
          </Form>
          <div className="mt-3">
            <Button
              type="primary"
              size="large"
              className="py-3 px-5"
              style={{ height: "auto", lineHeight: "1" }}
              onClick={() => {
                let params = { ...form.getFieldsValue() };
                params.icon = iconFile[0];
                params.images = [...images.map((item) => item.url)].join(",");
                params.install_job = install[0];
                // console.log(iconFile[0]);
                // console.log(images);
                // console.log(install[0]);
                // console.log(params);
                // return;
                onCreate(params);
              }}
            >
              {info.id ? "编辑" : "创建"}应用
            </Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

const List = () => {
  const [filterForm] = Form.useForm();
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNo: 1,
    total: 0,
  });
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [visibleInfo, setVisibleInfo] = useState({});

  const [dataSource, setDataSource] = useState([]);
  const { pathname } = useLocation();
  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "应用名称",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "应用信息",
      dataIndex: "author",
      key: "author",
      width: 500,
      render: (text, data) => (
        <div>
          <div>版本：{data.version}</div>
          <div>开发者：{data.author}</div>
          <div>描述：{data.intro}</div>
        </div>
      ),
    },
    {
      title: "应用类型",
      dataIndex: ["type", "name"],
      key: "type_id",
    },
    {
      title: "应用分类",
      dataIndex: ["classify", "name"],
      key: "classify_id",
    },
    {
      title: "是否精选",
      dataIndex: "is_selected",
      key: "is_selected",
      render: (text, data) => (text == 0 && "否") || (text == 1 && "是") || "-",
    },
    {
      title: "状态",
      dataIndex: "state",
      key: "state",
      render: (text, data) =>
        (text == 0 && "下架") || (text == 1 && "上架") || "-",
    },
    {
      title: "创建时间",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, data) => moment(text).format("YYYY/MM/DD HH:mm:ss"),
    },
    {
      title: "操作",
      key: "action",
      render: (text, data) => {
        return (
          <div>
            <Button
              type="link"
              size="small"
              onClick={() => {
                setVisibleCreate(true);
                setVisibleInfo({ ...data });
              }}
            >
              编辑
            </Button>
            <span>|</span>
            <Button
              type="link"
              danger
              size="small"
              onClick={() => {
                Modal.confirm({
                  title: "确定要删除该项吗?",
                  icon: <ExclamationCircleOutlined />,
                  content: "删除后将无法寻回，确认吗?",
                  onOk() {
                    onRemove({
                      id: data.id,
                    });
                  },
                  onCancel() {},
                });
              }}
            >
              删除
            </Button>
          </div>
        );
      },
    },
  ];

  const onCreate = async (values) => {
    // console.log(values)
    const {
      data: { code, data, msg },
    } = await SET_APPLICATION(values);

    if (code == 200) {
      message.success(msg);
      setVisibleInfo({});
      getList(pagination);
    } else {
      message.error(msg);
    }
    setVisibleCreate(false);
  };
  const onRemove = async (values) => {
    // console.log(values)
    const {
      data: { code, data, msg },
    } = await DEL_APPLICATION(values);
    if (code == 200) {
      message.success("删除成功");
      getList(pagination);
    } else {
      message.error(msg);
    }
    setVisibleCreate(false);
  };

  const getList = async (paginations) => {
    const {
      data: { code, data, msg },
    } = await GET_APPLICATION_LIST({ ...paginations });
    if (code == 200) {
      setDataSource(data.resource);
      setPagination({
        ...pagination,
        total: data.total,
      });
    }
  };
  const paginationChange = (pages) => {
    setPagination({
      ...pagination,
      pageNo: pages.current || 1,
      current: pages.current,
    });
    getList({
      ...pagination,
      pageNo: pages.current || 1,
      current: pages.current,
    });
    // console.log("pageNo: ", pages);
  };

  useEffect(() => {
    getList(pagination);
  }, []);

  return (
    <div className="goods-storage">
      <div className="btn-box">
        <Button
          type="primary"
          className="mr-3"
          onClick={() => {
            setVisibleCreate(true);
          }}
        >
          创建应用
        </Button>
      </div>
      <div className="table-action"></div>
      <Table
        rowKey={"id"}
        columns={columns}
        dataSource={dataSource}
        pagination={{
          pageSize: pagination.pageSize,
          total: pagination.total,
        }}
        onChange={paginationChange}
      />

      {/* 创建 */}
      {visibleCreate && (
        <CreateShopForm
          visible={visibleCreate}
          info={visibleInfo}
          onCreate={onCreate}
          onCancel={() => {
            setVisibleCreate(false);
            setVisibleInfo({});
          }}
        />
      )}
    </div>
  );
};

export default List;
