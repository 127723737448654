import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Route, Redirect, Link, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Table,
  Form,
  Input,
  Radio,
  Checkbox,
  Button,
  Modal,
  Cascader,
  message,
  Slider,
  InputNumber,
  Tag,
} from "antd";
import cookie from "react-cookies";
import "./list.less";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import api from "../../api";
import moment from "moment";
import qs from "qs";

const CreateShopForm = ({ visible, info = {}, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  const [formVals, setFormVals] = useState({});
  useEffect(() => {
    if (visible) {
      form.resetFields();
      if (info) {
        if (info.type == 3) {
          let url = info.url.split(":");
          info.url = url[0];
          info.port = url[1];
        }
        form.setFieldsValue({
          ...info,
        });
        setFormVals(info);
      }
    }
  }, [visible]);

  return (
    <Modal
      getContainer={false}
      visible={visible}
      width={450}
      footer={false}
      okText={"保存"}
      cancelText={"取消"}
      onOk={() => {
        // form.submit();
        onCreate(form.getFieldsValue());
      }}
      onCancel={onCancel}
      destroyOnClose
      className="modal-createshop"
      centered
    >
      <Row gutter={30}>
        <Col span={24}>
          <div className="title">
            <h1>{info.id ? "修改" : "创建"}任务</h1>
          </div>
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              cron: 300,
              cronNum: 300,
              ...info,
            }}
            name="form_in_modal"
            onValuesChange={(vals, allVals) => {
              // console.log(allVals)
              setFormVals(allVals);
            }}
          >
            <div>
              <Form.Item name="id" hidden>
                <Input />
              </Form.Item>
              <Form.Item name="type" label="监控类型">
                <Radio.Group
                  optionType="button"
                  options={[
                    {
                      label: "ping",
                      value: 1,
                    },
                    {
                      label: "https/http",
                      value: 2,
                    },
                    {
                      label: "port",
                      value: 3,
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item name="url" label="监控地址">
                <Input />
              </Form.Item>
              {(formVals.type == 3 && (
                <Form.Item name="port" label="端口">
                  <Input />
                </Form.Item>
              )) ||
                ""}
              <Form.Item label="周期">
                <Row gutter={30}>
                  <Col span={18}>
                    <Form.Item
                      name="cron"
                      extra={
                        <small>
                          {moment
                            .utc(formVals.cron * 1000)
                            .format("HH时 mm分 ss秒")}
                        </small>
                      }
                    >
                      <Slider
                        max={86399}
                        min={5}
                        step={1}
                        tipFormatter={(val) => {
                          return moment
                            .utc(val * 1000)
                            .format("HH时 mm分 ss秒");
                        }}
                        // tooltipVisible
                        tooltipPlacement="bottom"
                        onChange={(val) => {
                          form.setFieldsValue({
                            cronNum: val,
                          });
                          setFormVals({
                            ...formVals,
                            cronNum: val,
                            cron: val,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name="cronNum">
                      <InputNumber
                        max={86399}
                        min={5}
                        onChange={(val) => {
                          // console.log(val);
                          form.setFieldsValue({
                            cron: val,
                          });
                          setFormVals({
                            ...formVals,
                            cronNum: val,
                            cron: val,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item name="name" label="自定义任务名称">
                <Input />
              </Form.Item>
            </div>
          </Form>
          <div className="mt-3">
            <Button
              type="primary"
              size="large"
              className="py-3 px-5"
              style={{ height: "auto", lineHeight: "1" }}
              onClick={() => {
                onCreate(form.getFieldsValue());
              }}
            >
              {info.id ? "修改" : "创建"}任务
            </Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

const List = () => {
  const [filterForm] = Form.useForm();
  const [pagination, setPagination] = useState({
    limit: 10,
    page: 1,
    totalRecords: 0,
  });
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [visibleInfo, setVisibleInfo] = useState({});

  const [dataSource, setDataSource] = useState([]);
  const { pathname } = useLocation();
  const columns = [
    {
      title: "名称",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "网址/IP",
      dataIndex: "url",
      key: "url",
    },
    {
      title: "类型",
      dataIndex: "type",
      key: "type",
      render: (text, data) => {
        return (
          (data.type == 1 && <Tag color="#87d068">ping</Tag>) ||
          (data.type == 2 && <Tag color="#2db7f5">HTTPS/HTTP</Tag>) ||
          (data.type == 3 && <Tag color="#108ee9">port</Tag>) ||
          "-"
        );
      },
    },
    {
      title: "监控频率",
      dataIndex: "cron",
      key: "cron",
      render: (text, data) => text + " 秒/次",
    },
    {
      title: "状态",
      dataIndex: "state",
      key: "state",
      render: (text, data) => (text == 1 && "正常") || "暂停",
    },
    {
      title: "创建时间",
      dataIndex: "create_time",
      key: "create_time",
      render: (text, data) => moment(text).format("YYYY/MM/DD HH:mm:ss"),
    },
    {
      title: "最后修改时间",
      dataIndex: "update_time",
      key: "update_time",
      render: (text, data) => moment(text).format("YYYY/MM/DD HH:mm:ss"),
    },
    {
      title: "操作",
      key: "action",
      render: (text, data) => {
        return (
          <div>
            <Link to={`/monitor/charts?id=${data.id}`}>
              <Button type="link" size="small">
                报表
              </Button>
            </Link>
            <span>|</span>
            {(data.state == 0 && (
              <Button
                type="link"
                size="small"
                onClick={() => {
                  Modal.confirm({
                    title: "确定要开启该任务吗?",
                    icon: <ExclamationCircleOutlined />,
                    onOk() {
                      cronStart({
                        cron_id: data.id,
                      });
                    },
                    onCancel() {},
                  });
                }}
              >
                开始
              </Button>
            )) || (
              <Button
                type="link"
                danger
                size="small"
                onClick={() => {
                  Modal.confirm({
                    title: "确定要暂停该任务吗?",
                    icon: <ExclamationCircleOutlined />,
                    onOk() {
                      cronStop({
                        cron_id: data.id,
                      });
                    },
                    onCancel() {},
                  });
                }}
              >
                暂停
              </Button>
            )}
            <span>|</span>
            <Button
              type="link"
              size="small"
              onClick={() => {
                setVisibleCreate(true);
                setVisibleInfo({ ...data });
              }}
            >
              编辑信息
            </Button>
            <span>|</span>
            <Button
              type="link"
              danger
              size="small"
              onClick={() => {
                Modal.confirm({
                  title: "确定要删除该任务吗?",
                  icon: <ExclamationCircleOutlined />,
                  content: "删除后将无法寻回，确认吗?",
                  onOk() {
                    onRemove({
                      id: data.id,
                    });
                  },
                  onCancel() {},
                });
              }}
            >
              删除
            </Button>
          </div>
        );
      },
    },
  ];

  const onCreate = async (values) => {
    let res;
    delete values.cronNum;
    if (values.type == 3) {
      values.url = values.url + ":" + values.port;
    }
    values.cron = values.cron + "";
    if (visibleInfo.id) {
      values.cron_id = values.id;
      delete values.id;
      res = await api.cronUpdate(values);
    } else {
      res = await api.cronAdd(values);
    }
    if (res.status == 200) {
      message.success(res.data.Message);
      getList();
    } else {
      message.error(res.data.Message);
    }
    setVisibleCreate(false);
  };
  const cronStart = async (values) => {
    let res = await api.cronStart(values);
    if (res.status == 200) {
      message.success(res.data.Message);
      getList();
    } else {
      message.error(res.data.Message);
    }
  };
  const cronStop = async (values) => {
    let res = await api.cronStop(values);
    if (res.status == 200) {
      message.success(res.data.Message);
      getList();
    } else {
      message.error(res.data.Message);
    }
  };
  const onRemove = async (values) => {
    let res = await api.cronDelete(values);
    if (res.status == 200) {
      message.success("删除成功");
      getList();
    } else {
      message.error(res.data.Message);
    }
    setVisibleCreate(false);
  };

  const getList = async (paginations) => {
    const res = await api.cronList({ ...paginations });
    if (res.status == 200) {
      setDataSource(res.data.Data);
      setPagination({
        ...pagination,
        totalRecords: res.data.Data.totalRecords,
      });
    }
  };
  const paginationChange = (pages) => {
    setPagination({
      ...pagination,
      page: pages.current || 1,
      current: pages.current,
    });
    getList({
      ...pagination,
      page: pages.current || 1,
      current: pages.current,
    });
    console.log("Page: ", pages);
  };

  useEffect(() => {
    getList(pagination);
  }, []);

  return (
    <div className="goods-storage">
      <div className="btn-box">
        <Button
          type="primary"
          className="mr-3"
          onClick={() => {
            setVisibleCreate(true);
          }}
        >
          新建任务
        </Button>
      </div>
      {/* <div className="search-box mb-3">
        <Form form={filterForm}>
          <Row gutter={30}>
            <Col span={4}>
              <Form.Item name="nameLike" label="社区名称">
                <Input placeholder="输入社区名称" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="nameLike" label="居委会名称">
                <Input placeholder="输入居委会名称" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="nameLike" label="负责人">
                <Input placeholder="输入负责人" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="nameLike" label="联系电话">
                <Input placeholder="输入联系电话" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="nameLike" label="状态">
                <Select />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Button type="primary" className="mr-3">
                筛选
              </Button>
              <Button className="mr-3">导出</Button>
              <Button type="link">重置</Button>
            </Col>
          </Row>
        </Form>
      </div> */}
      <div className="table-action"></div>
      <Table
        rowKey={"id"}
        columns={columns}
        dataSource={dataSource}
        pagination={{
          pageSize: pagination.pageSize,
          total: pagination.totalRecords,
        }}
        onChange={paginationChange}
      />

      {/* 创建 */}
      {visibleCreate && (
        <CreateShopForm
          visible={visibleCreate}
          info={visibleInfo}
          onCreate={onCreate}
          onCancel={() => {
            setVisibleCreate(false);
            setVisibleInfo({});
          }}
        />
      )}
    </div>
  );
};

export default List;
