import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
import qs from "qs";

/**
 * 获取商品分类列表
 * @param {Object} parm 请求参数
 * @param {Number} parm.pageSize 每页数量
 * @param {Number} parm.pageNo 当前页码
 * @returns
 */
export const GET_GOODS_CATEGORY_LIST = async (parm) =>
  await instance.post(`${base}/admin/goods/category/list`, parm);

/**
 * 获取商品分类详情
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 查询项ID
 * @returns
 */
export const GET_GOODS_CATEGORY_DETAIL = async () =>
  await instance.get(`${base}/admin/goods/category/detail`);

/**
 * 设置商品分类
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 商品分类项ID
 * @param {String} parm.name 商品分类名称
 * @param {String} parm.icon 商品分类图标
 * @param {String} parm.intro 商品分类简介
 * @param {Number} parm.category_id 所属上级分类 0为顶级
 * @returns
 */
export const SET_GOODS_CATEGORY = async (parm) =>
  await instance.post(`${base}/admin/goods/category/set`, parm);

/**
 * 删除商品分类
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 等级项ID
 * @returns
 */
export const DEL_GOODS_CATEGORY = async (parm) =>
  await instance.delete(`${base}/admin/goods/category/del`, {
    data: { ...parm },
  });
