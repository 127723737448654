import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
import qs from "qs";

/**
 * 获取安装包分类列表
 * @param {Object} parm 请求参数
 * @param {Number} parm.pageSize 每页数量
 * @param {Number} parm.pageNo 当前页码
 * @returns
 */
export const GET_INSTALL_CLASSIFY_LIST = async (parm) =>
  await instance.post(`${base}/admin/install/classify/list`, parm);

/**
 * 获取安装包分类详情
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 安装包分类ID
 * @returns
 */
export const GET_INSTALL_CLASSIFY_DETAIL = async () =>
  await instance.get(`${base}/admin/install/classify/detail`);

/**
 * 设置安装包分类
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 安装包分类ID
 * @param {String} parm.name 安装包分类名称
 * @returns
 */
export const SET_INSTALL_CLASSIFY = async (parm) =>
  await instance.post(`${base}/admin/install/classify/set`, parm);

/**
 * 删除安装包分类
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 安装包分类ID
 * @returns
 */
export const DEL_INSTALL_CLASSIFY = async (parm) =>
  await instance.delete(`${base}/admin/install/classify/del`, {
    data: { ...parm },
  });
