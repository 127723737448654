import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
import qs from "qs";

/**
 * 获取销售渠道列表
 * @param {Object} parm 请求参数
 * @param {Number} parm.pageSize 每页数量
 * @param {Number} parm.pageNo 当前页码
 * @returns
 */
export const GET_SALES_CHANNEL_LIST = async (parm) =>
  await instance.post(`${base}/admin/saleschannels/list`, parm);

/**
 * 获取销售渠道详情
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 销售渠道ID
 * @returns
 */
export const GET_SALES_CHANNEL_DETAIL = async () =>
  await instance.get(`${base}/admin/saleschannels/detail`);

/**
 * 设置销售渠道
 * @param {Object} parm 请求参数
 * @param {Number} id 销售渠道ID（修改用）
 * @param {String} logo 公司logo
 * @param {String} name 公司名称
 * @param {String} type 渠道类型
 * @param {String} net_type 网络渠道类型 1：京东 2：拼多多 3：天猫 4：淘宝
 * @param {String} address 地址
 * @param {String} mobile 联系手机号
 * @param {String} contact 联系人
 * @param {String} url 购买链接
 * @returns
 */
export const SET_SALES_CHANNEL = async (parm) =>
  await instance.post(`${base}/admin/saleschannels/set`, parm);

/**
 * 删除销售渠道
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 销售渠道ID
 * @returns
 */
export const DEL_SALES_CHANNEL = async (parm) =>
  await instance.delete(`${base}/admin/saleschannels/del`, {
    data: { ...parm },
  });
