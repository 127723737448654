import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
import qs from "qs";

/**
 * 获取订单列表
 * @param {Object} parm 请求参数
 * @param {Number} parm.pageSize 每页数量
 * @param {Number} parm.pageNo 当前页码
 * @returns
 */
export const GET_GOODS_ORDER_LIST = async (parm) =>
  await instance.post(`${base}/admin/order/list`, parm);

/**
 * 获取订单详情
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 查询项ID
 * @returns
 */
export const GET_GOODS_ORDER_DETAIL = async () =>
  await instance.get(`${base}/admin/order/detail`);

/**
 * 设置订单
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 订单项ID
 * @returns
 */
export const SET_GOODS_ORDER = async (parm) =>
  await instance.post(`${base}/admin/order/set`, parm);

/**
 * 删除订单
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 等级项ID
 * @returns
 */
export const DEL_GOODS_ORDER = async (parm) =>
  await instance.delete(`${base}/admin/order/del`, {
    data: { ...parm },
  });
