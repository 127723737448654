import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
import qs from "qs";

/**
 * 上传文件
 * @param {Object} parm 请求参数
 * @param {Number} parm.file 文件
 * @returns
 */
export const UPLOAD_FILE = async (parm) =>
  await instance.post(`${base}/admin/upload`, parm);
  /**
   * 版本上传文件
   * @param {Object} parm 请求参数
   * @param {Number} parm.file 文件
   * @returns
   */
  export const VERSION_UPLOAD_FILE = async (parm) =>
    await instance.post(`${base}/admin/vupload`, parm);
  
