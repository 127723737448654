import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
import qs from "qs";

/**
 * 获取系统配置列表
 * @param {Object} parm 请求参数
 * @param {Number} parm.pageSize 每页数量
 * @param {Number} parm.pageNo 当前页码
 * @returns
 */
export const GET_SYSTEM_SETTING_LIST = async (parm) =>
  await instance.post(`${base}/admin/setting/list`, parm);

/**
 * 获取系统配置详情
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 系统配置ID
 * @returns
 */
export const GET_SYSTEM_SETTING_DETAIL = async () =>
  await instance.get(`${base}/admin/setting/detail`);

/**
 * 设置系统配置
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 系统配置ID
 * @param {String} parm.name 系统配置名称
 * @returns
 */
export const SET_SYSTEM_SETTING = async (parm) =>
  await instance.post(`${base}/admin/setting/set`, parm);

/**
 * 删除系统配置
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 系统配置ID
 * @returns
 */
export const DEL_SYSTEM_SETTING = async (parm) =>
  await instance.delete(`${base}/admin/setting/del`, { data: { ...parm } });
