import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
import qs from "qs";

/**
 * 获取优惠券列表
 * @param {Object} parm 请求参数
 * @param {Number} parm.pageSize 每页数量
 * @param {Number} parm.pageNo 当前页码
 * @returns
 */
export const GET_COUPON_LIST = async (parm) =>
  await instance.post(`${base}/admin/coupon/list`, parm);

/**
 * 获取优惠券详情
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 等级项ID
 * @returns
 */
export const GET_COUPON_DETAIL = async () =>
  await instance.get(`${base}/admin/coupon/detail`);

/**
 * 设置优惠券
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 优惠券ID
 * @param {String} parm.title 优惠券标题
 * @param {Number} parm.use_range 使用范围 0:无限制 1:购买商品 2:购买授权
 * @param {String} parm.use_goods_category 指定商品分类减免
 * @param {String} parm.use_goods 指定商品减免
 * @param {Number} parm.threshold_amount 门槛金额
 * @param {Number} parm.amount 优惠金额
 * @param {Number} parm.num 发放数量
 * @param {Number} parm.quantity 每人可领取数量
 * @param {Datetime} parm.end_time 优惠券可用结束时间
 * @param {Number} parm.end_day 优惠券领取后有效天数
 * @param {Number} parm.state 状态 0:禁用 1:正常
 * @returns
 */
export const SET_COUPON = async (parm) =>
  await instance.post(`${base}/admin/coupon/set`, parm);

/**
 * 删除优惠券
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 等级项ID
 * @returns
 */
export const DEL_COUPON = async (parm) =>
  await instance.delete(`${base}/admin/coupon/del`, { data: { ...parm } });
