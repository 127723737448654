import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
import qs from "qs";

/**
 * 获取应用列表
 * @param {Object} parm 请求参数
 * @param {Number} parm.pageSize 每页数量
 * @param {Number} parm.pageNo 当前页码
 * @returns
 */
export const GET_APPLICATION_LIST = async (parm) =>
  await instance.post(`${base}/admin/application/list`, parm);

/**
 * 获取应用详情
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 应用ID
 * @returns
 */
export const GET_APPLICATION_DETAIL = async () =>
  await instance.get(`${base}/admin/application/detail`);

/**
 * 设置应用
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 应用ID（修改用）
 * @param {String} parm.name 应用名称
 * @param {String} parm.author 开发者
 * @param {String} parm.icon 图标
 * @param {String} parm.images 大图
 * @param {String} parm.intro 描述
 * @param {Enum} parm.is_selected 是否精选 0:否 1:是
 * @param {Number} parm.classify_id 分类ID
 * @param {Number} parm.type_id 类型ID
 * @param {String} parm.version 版本
 * @param {String} parm.port_map 端口地图
 * @param {String} parm.index 首页地址
 * @param {String} parm.install_job 安装脚本
 * @param {String} parm.uninstall_job 卸载脚本
 * @param {Enum} parm.state 状态 0:下架 1:上架
 * @returns
 */
export const SET_APPLICATION = async (parm) =>
  await instance.post(`${base}/admin/application/set`, parm);

/**
 * 删除应用
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 应用ID
 * @returns
 */
export const DEL_APPLICATION = async (parm) =>
  await instance.delete(`${base}/admin/application/del`, { data: { ...parm } });
