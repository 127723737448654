import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Route, Redirect, Link, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Table,
  Form,
  Input,
  Switch,
  Checkbox,
  Button,
  Modal,
  Cascader,
  message,
  Slider,
  InputNumber,
  Tag,
  Upload,
  Select,
  Empty,
  Pagination,
} from "antd";
import cookie from "react-cookies";
import "./list.less";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";
import qs from "qs";
import {
  ENM_ORDER_STATE,
  ENM_PAY_STATE,
  ENM_ORDER_PAY_TYPE,
} from "../../common/enum";
import {
  GET_GOODS_ORDER_LIST,
  GET_GOODS_ORDER_DETAIL,
  SET_GOODS_ORDER,
} from "../../api/goodsOrder";

const ChangeMoney = ({ visible, info = {}, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  const [formVals, setFormVals] = useState({});

  useEffect(() => {
    if (visible) {
      form.resetFields();
      info.changeType = "1";
      if (info) {
        // console.log(info);
        form.setFieldsValue({
          ...info,
        });
        setFormVals(info);
      }
    }
  }, [visible]);

  return (
    <Modal
      getContainer={false}
      open={visible}
      width={650}
      footer={false}
      okText={"保存"}
      cancelText={"取消"}
      onOk={() => {
        onCreate(form.getFieldsValue());
      }}
      onCancel={onCancel}
      destroyOnClose
      className="modal-createshop"
      centered
    >
      <Row gutter={30}>
        <Col span={24}>
          <div className="title">
            <h1>设置订单状态</h1>
          </div>
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              ...info,
            }}
            name="form_in_modal"
            onValuesChange={(vals, allVals) => {
              // console.log(allVals)
              setFormVals(allVals);
            }}
          >
            <div>
              <Form.Item name="id" hidden>
                <Input />
              </Form.Item>
              <Form.Item name="state" label="订单状态">
                <Select
                  options={[
                    {
                      label: "已关闭",
                      value: 0,
                    },
                    {
                      label: "可使用",
                      value: 1,
                    },
                    {
                      label: "已发货",
                      value: 2,
                    },
                    {
                      label: "已结束",
                      value: 3,
                    },
                  ]}
                />
              </Form.Item>
              {(formVals.state == 2 && (
                <Form.Item name="express_company" label="快递公司">
                  <Input />
                </Form.Item>
              )) ||
                ""}
              {(formVals.state == 2 && (
                <Form.Item name="express_number" label="快递单号">
                  <Input />
                </Form.Item>
              )) ||
                ""}
            </div>
          </Form>
          <div className="mt-3">
            <Button
              type="primary"
              size="large"
              className="py-3 px-5"
              style={{ height: "auto", lineHeight: "1" }}
              onClick={() => {
                onCreate(form.getFieldsValue());
              }}
            >
              保存
            </Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

const List = () => {
  const [filterForm] = Form.useForm();
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNo: 1,
    total: 0,
  });
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [visibleInfo, setVisibleInfo] = useState({});

  const [visibleChange, setVisibleChange] = useState(false);

  const [dataSource, setDataSource] = useState([]);
  const { pathname } = useLocation();

  const getList = async (paginations) => {
    const {
      data: { code, data, msg },
    } = await GET_GOODS_ORDER_LIST({ ...paginations });
    if (code == 200) {
      setDataSource(data.resource);
      setPagination({
        ...pagination,
        pageNo: paginations.pageNo,
        total: data.total,
      });
    }
  };

  // 分页操作
  const onPageChange = (page) => {
    setPagination({
      ...pagination,
      pageNo: page,
    });
    getList({
      ...pagination,
      pageNo: page,
    });
  };

  useEffect(() => {
    getList(pagination);
  }, []);

  // 修改订单
  const onChange = async (values) => {
    // console.log(values)

    let res = await SET_GOODS_ORDER(values);

    const {
      data: { code, data, msg },
    } = res;

    if (code == 200) {
      message.success(msg);
      setVisibleInfo({});
      getList(pagination);
    } else {
      message.error(msg);
    }

    setVisibleChange(false);
  };

  return (
    <div className="goods-storage">
      <div className="table-action mb-3">
        <Form
          layout="inline"
          initialValues={{
            state: "",
            pay_state: "",
          }}
          onValuesChange={(cVal, vals) => {
            console.log(cVal, vals);
            let where = {
              ...pagination.where,
              ...cVal,
            };

            setPagination({
              ...pagination,
              where,
            });
          }}
        >
          <Form.Item label="订单状态" name="state">
            <Select
              options={[
                {
                  label: "全部",
                  value: "",
                },
                {
                  label: "已关闭",
                  value: 0,
                },
                {
                  label: "可使用",
                  value: 1,
                },
                {
                  label: "已发货",
                  value: 2,
                },
                {
                  label: "已结束",
                  value: 3,
                },
              ]}
              allowClear
              style={{ width: 120 }}
            />
          </Form.Item>
          <Form.Item label="支付状态" name="pay_state">
            <Select
              options={[
                {
                  label: "全部",
                  value: "",
                },
                {
                  label: "未支付",
                  value: 0,
                },
                {
                  label: "已支付",
                  value: 1,
                },
              ]}
              allowClear
              style={{ width: 120 }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              onClick={() => {
                setPagination({
                  ...pagination,
                  pageNo: 1,
                  current: 1,
                });
                getList({
                  ...pagination,
                  pageNo: 1,
                  current: 1,
                });
              }}
            >
              搜索
            </Button>
          </Form.Item>
        </Form>
      </div>

      <div className="order context">
        <div className="head">
          <div className="item intro">订单信息</div>
          <div className="item amount">小计</div>
          <div className="item contact">收货人</div>
          <div className="item user">联系方式</div>
          <div className="item actions">操作</div>
        </div>
        <div className="body">
          <div className="list">
            {dataSource.map((item, index) => (
              <div className="list-item" key={index}>
                <div className="head">
                  <div className="order">
                    <div className="item time">
                      <span className="label">下单时间：</span>
                      <span className="value">{item.created_at}</span>
                    </div>
                    <div className="item code">
                      <span className="label">订单号：</span>
                      <span className="value">{item.order_code}</span>
                    </div>
                    <div className="item code">
                      <span className="label">微信订单号：</span>
                      <span className="value">{item.wx_order}</span>
                    </div>
                  </div>
                  <div className="address">
                    <div className="item address">
                      <span className="value">
                        {item.provinces_name}
                        {item.cities_name}
                        {item.areas_name}
                        {item.address}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="order">
                  <div className="item amount">
                    <span className="label">订单金额：</span>
                    <span className="value">{item.pay_money}</span>
                  </div>
                  <div className="item state">
                    <span className="label">订单状态：</span>
                    <span className="value">{ENM_ORDER_STATE(item.state)}</span>
                  </div>
                  <div className="item paystate">
                    <span className="label">支付状态：</span>
                    <span className="value">
                      {ENM_PAY_STATE(item.pay_state)}
                    </span>
                  </div>
                  <div className="item paytype">
                    <span className="label">支付方式：</span>
                    <span className="value">
                      {ENM_PAY_STATE(
                        item.pay_state,
                        ENM_ORDER_PAY_TYPE(item.pay_type)
                      )}
                    </span>
                  </div>
                  <div className="item paytime">
                    <span className="label">支付时间：</span>
                    <span className="value">
                      {ENM_PAY_STATE(item.pay_state, item.pay_time || "-")}
                    </span>
                  </div>
                </div>
                <table className="goods-list">
                  <tbody>
                    {item?.goods_sku &&
                      JSON.parse(item.goods_sku).map((sku, gInd) => {
                        const length = JSON.parse(item.goods_sku).length;
                        // const length = 3;
                        const goods = JSON.parse(item.goods);
                        // console.log(goods)
                        const goodsIndex = goods.findIndex(
                          (it) => it.id === sku.goods_id
                        );

                        const skus = [];
                        const skuInfo = JSON.parse(sku.sku);
                        Object.keys(skuInfo).map((it, ind) => {
                          if (it.indexOf("skuValue") >= 0) {
                            let skuIndex = it.split("skuValue")[1];
                            skus.push(
                              `${skuInfo["skuName" + skuIndex]}:${skuInfo[it]}`
                            );
                          }
                        });
                        return (
                          <tr className="goods-list-item" key={gInd}>
                            <td className="intro">
                              <div className="item intro">
                                <div className="cont">
                                  <div className="cover">
                                    <img src={goods[goodsIndex]?.cover} />
                                  </div>
                                  <div className="info">
                                    <div
                                      className="title"
                                      title={goods[goodsIndex]?.name}
                                    >
                                      {goods[goodsIndex]?.name}
                                    </div>
                                    <div className="sku" title={skus.join(",")}>
                                      {skus.map((sItem, sInd) => (
                                        <div index={sInd}>{sItem}</div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <div className="number">
                                  <div className="amount">
                                    ￥
                                    {skuInfo.event_price || skuInfo.sale_price}
                                    <small
                                      className="ml-1"
                                      style={{ color: "#999" }}
                                    >
                                      [单价]
                                    </small>
                                    + ￥
                                    {goods[goodsIndex].is_free_shipping
                                      ? 0
                                      : goods[goodsIndex].postage}
                                    <small
                                      className="ml-1"
                                      style={{ color: "#999" }}
                                    >
                                      [邮费]
                                    </small>
                                  </div>
                                  <div className="num text-right">
                                    x {sku?.number}
                                    <small
                                      className="ml-1"
                                      style={{ color: "#999" }}
                                    >
                                      [数量]
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="amount">
                              <div className="item amount">
                                ￥
                                {(
                                  ((skuInfo.event_price || skuInfo.sale_price) +
                                    (goods[goodsIndex].is_free_shipping
                                      ? 0
                                      : goods[goodsIndex].postage)) *
                                  sku?.number
                                ).toFixed(2)}
                              </div>
                            </td>
                            <td
                              className="contact"
                              rowSpan={length}
                              style={{
                                display: (gInd == 0 && "table-cell") || "none",
                              }}
                            >
                              <div className="item contact">
                                <div className="name">{item.contact_name}</div>
                              </div>
                            </td>
                            <td
                              className="user"
                              rowSpan={length}
                              style={{
                                display: (gInd == 0 && "table-cell") || "none",
                              }}
                            >
                              <div className="item user">
                                <div className="mobile">
                                  {item.contact_mobile}
                                </div>
                              </div>
                            </td>
                            <td
                              className="actions"
                              rowSpan={length}
                              style={{
                                display: (gInd == 0 && "table-cell") || "none",
                              }}
                            >
                              <div className="item actions">
                                <Button
                                  type="link"
                                  size="small"
                                  onClick={() => {
                                    setVisibleChange(true);
                                    setVisibleInfo({ ...item });
                                  }}
                                >
                                  设置订单状态
                                </Button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                {(item.express_company && item.express_number && (
                  <div className="express">
                    <span className="item">
                      快递公司：{item.express_company}
                    </span>
                    <span className="item">
                      快递单号：{item.express_number}
                    </span>
                  </div>
                )) ||
                  ""}
              </div>
            ))}
          </div>
          {!dataSource.length && (
            <div className="empty py-5">
              <Empty />
            </div>
          )}
        </div>
        <div className="foot">
          {!!dataSource.length && (
            <div className="pagination text-right">
              <Pagination
                current={pagination.pageNo}
                total={pagination.total}
                onChange={onPageChange}
                showSizeChanger={false}
              />
            </div>
          )}
        </div>
      </div>

      {/* 修改 */}
      {visibleChange && (
        <ChangeMoney
          visible={visibleChange}
          info={visibleInfo}
          onCreate={onChange}
          onCancel={() => {
            setVisibleChange(false);
            setVisibleInfo({});
          }}
        />
      )}
    </div>
  );
};

export default List;
