import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
import qs from "qs";

/**
 * 获取代理商等级列表
 * @param {Object} parm 请求参数
 * @param {Number} parm.pageSize 每页数量
 * @param {Number} parm.pageNo 当前页码
 * @returns
 */
export const GET_AGENT_LIST = async (parm) =>
  await instance.post(`${base}/admin/agent/list`, parm);

/**
 * 获取代理商等级详情
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 等级项ID
 * @returns
 */
export const GET_AGENT_DETAIL = async () =>
  await instance.get(`${base}/admin/agent/detail`);

/**
 * 设置代理商等级
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 等级项ID
 * @param {String} parm.name 等级名称
 * @param {String} parm.threshold 门槛积分
 * @returns
 */
export const SET_AGENT = async (parm) =>
  await instance.post(`${base}/admin/agent/set`, parm);

/**
 * 删除代理商等级
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 等级项ID
 * @returns
 */
export const DEL_AGENT = async (parm) =>
  await instance.delete(`${base}/admin/agent/del`, { data: { ...parm } });
