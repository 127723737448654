import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
import qs from "qs";

/**
 * 获取商品列表
 * @param {Object} parm 请求参数
 * @param {Number} parm.pageSize 每页数量
 * @param {Number} parm.pageNo 当前页码
 * @returns
 */
export const GET_GOODS_LIST = async (parm) =>
  await instance.post(`${base}/admin/goods/list`, parm);

/**
 * 获取商品详情
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 查询项ID
 * @returns
 */
export const GET_GOODS_DETAIL = async () =>
  await instance.get(`${base}/admin/goods/detail`);

/**
 * 设置商品
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 商品项ID
 * @param {Number} parm.category_id 商品分类
 * @param {String} parm.name 商品名称
 * @param {String} parm.promotion_title 促销标题
 * @param {Array} parm.sku 商品规格
 * @param {Array} parm.sku_json 商品规格json
 * @param {String} parm.intro 商品描述
 * @param {String} parm.cover 商品封面
 * @param {String} parm.video 商品视频
 * @param {String} parm.imaegs 商品多图
 * @param {Number} parm.vip_level_id 会员等级限制 0:不限制
 * @param {String} parm.pc_detail PC端商品详情
 * @param {String} parm.mob_detail 移动端商品详情
 * @param {String} parm.unit 商品单位
 * @param {Number} parm.is_free_shipping 是否包邮 0:否 1:是
 * @param {Number} parm.postage 邮费
 * @param {String} parm.logistics_tips 物流备注
 * @param {Number} parm.is_delete 是否删除 0:否 1:是
 * @returns
 */
export const SET_GOODS = async (parm) =>
  await instance.post(`${base}/admin/goods/set`, parm);

/**
 * 删除商品
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 等级项ID
 * @returns
 */
export const DEL_GOODS = async (parm) =>
  await instance.delete(`${base}/admin/goods/del`, {
    data: { ...parm },
  });
