import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
import qs from "qs";

/**
 * 管理员登录
 * @param {Object} parm 请求参数
 * @param {String} parm.account 账号
 * @param {String} parm.password 密码
 * @returns
 */
export const LOGIN = async (parm) =>
  await instance.post(`${base}/admin/login`, parm);

/**
 * 管理员信息
 * @returns
 */
export const GET_ADMIN_INFO = async () =>
  await instance.get(`${base}/admin/info`);

/**
 * 管理员刷新token
 * @returns
 */
export const REFRESH_ADMIN_TOKEN = async (parm) =>
  await instance.post(`${base}/admin/refresh`, parm);

/**
 * 管理员退出
 * @returns
 */
export const LOGIN_OUT = async (parm) =>
  await instance.post(`${base}/admin/logout`, parm);

export default {
  //重置密码
  async resetPwd(params) {
    return await instance
      .post(`${base}/public/reset_pwd`, params)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
};
