import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Route, Redirect, Link, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Table,
  Form,
  Input,
  Switch,
  Checkbox,
  Button,
  Modal,
  Cascader,
  message,
  Slider,
  InputNumber,
  Tag,
  Upload,
  Select,
} from "antd";
import cookie from "react-cookies";
import "./list.less";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";
import qs from "qs";

import { UPLOAD_FILE } from "../../api/common";
import {
  GET_GOODS_CATEGORY_LIST,
  GET_GOODS_CATEGORY_DETAIL,
  SET_GOODS_CATEGORY,
  DEL_GOODS_CATEGORY,
} from "../../api/goodsCategory";

import { array2tree } from "../../common/utils";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const beforeUpload = (file) => {
  const isLt2M = file.size / 1024 / 1024 < 200;
  if (!isLt2M) {
    message.error("文件大于200MB!");
  }
  return isLt2M;
};

const CreateShopForm = ({
  visible,
  info = {},
  onCreate,
  onCancel,
  category,
}) => {
  const [form] = Form.useForm();
  const [formVals, setFormVals] = useState({});
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  // 图标
  const [iconFile, setIconFile] = useState([]);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        上传
      </div>
    </div>
  );
  useEffect(() => {
    if (visible) {
      form.resetFields();
      if (info) {
        info.icon && setIconFile([info.icon]);

        form.setFieldsValue({
          ...info,
        });
        setFormVals(info);
      }
    }
  }, [visible]);

  return (
    <Modal
      getContainer={false}
      open={visible}
      width={650}
      footer={false}
      okText={"保存"}
      cancelText={"取消"}
      onOk={() => {
        onCreate(form.getFieldsValue());
      }}
      onCancel={onCancel}
      destroyOnClose
      className="modal-createshop"
      centered
    >
      <Row gutter={30}>
        <Col span={24}>
          <div className="title">
            <h1>{info.id ? "编辑" : "创建"}商品分类</h1>
          </div>
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              isFullPackage: false,
              ...info,
            }}
            name="form_in_modal"
            onValuesChange={(vals, allVals) => {
              // console.log(allVals)
              setFormVals(allVals);
            }}
          >
            <div>
              <Form.Item name="id" hidden>
                <Input />
              </Form.Item>
              <Form.Item name="icon" label="图标">
                <Upload
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  customRequest={async (params) => {
                    setLoading(true);
                    const { file, onSuccess, onError } = params;
                    const formData = new FormData();
                    formData.append("file", file);

                    const {
                      data: { data, msg, code },
                    } = await UPLOAD_FILE(formData);

                    if (code == 200) {
                      setIconFile([data]);
                    }
                    console.log(data);
                    setLoading(false);
                  }}
                  beforeUpload={beforeUpload}
                >
                  {iconFile[0] ? (
                    <img
                      src={iconFile[0]}
                      alt="avatar"
                      style={{
                        maxHeight: "80px",
                      }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>
              <Form.Item name="name" label="分类名称">
                <Input />
              </Form.Item>
              <Form.Item name="category_id" label="上级分类">
                <Select
                  options={[
                    {
                      label: "顶级",
                      value: 0,
                    },
                    ...category
                      .filter((item) => item.id != info.id)
                      .map((item) => ({
                        label: item.name,
                        value: item.id,
                      })),
                  ]}
                />
              </Form.Item>
              <Form.Item name="intro" label="简介">
                <Input.TextArea rows={3} />
              </Form.Item>
            </div>
          </Form>
          <div className="mt-3">
            <Button
              type="primary"
              size="large"
              className="py-3 px-5"
              style={{ height: "auto", lineHeight: "1" }}
              onClick={() => {
                let params = { ...form.getFieldsValue() };
                params.icon = iconFile[0];
                // console.log(iconFile[0]);
                // console.log(images);
                // console.log(install[0]);
                // console.log(params);
                // return;
                onCreate(params);
              }}
            >
              {info.id ? "编辑" : "创建"}商品分类
            </Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

const Category = () => {
  const [filterForm] = Form.useForm();
  const [pagination, setPagination] = useState({
    pageSize: 99999,
    pageNo: 1,
    total: 0,
  });
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [visibleInfo, setVisibleInfo] = useState({});

  const [dataSource, setDataSource] = useState([]);
  const [category, setCategory] = useState([]);
  const { pathname } = useLocation();
  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "图标",
      dataIndex: "icon",
      key: "icon",
      render: (text, data) => <img src={text} style={{ width: "50px" }} />,
    },
    {
      title: "商品分类名称",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "简介",
      dataIndex: "intro",
      key: "intro",
    },
    {
      title: "所属父级",
      dataIndex: "category_id",
      key: "category_id",
      render: (text, data) =>
        (text == 0 && "顶级") ||
        dataSource.find((item) => item.id == text)?.name,
    },
    {
      title: "创建时间",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, data) => moment(text).format("YYYY/MM/DD HH:mm:ss"),
    },
    {
      title: "操作",
      key: "action",
      render: (text, data) => {
        return (
          <div>
            <Button
              type="link"
              size="small"
              onClick={() => {
                setVisibleCreate(true);
                setVisibleInfo({ ...data });
              }}
            >
              编辑
            </Button>
            <span>|</span>
            <Button
              type="link"
              danger
              size="small"
              onClick={() => {
                Modal.confirm({
                  title: "确定要删除该项吗?",
                  icon: <ExclamationCircleOutlined />,
                  content: "删除后将无法寻回，确认吗?",
                  onOk() {
                    onRemove({
                      id: data.id,
                    });
                  },
                  onCancel() {},
                });
              }}
            >
              删除
            </Button>
          </div>
        );
      },
    },
  ];

  const onCreate = async (values) => {
    // console.log(values)
    const {
      data: { code, data, msg },
    } = await SET_GOODS_CATEGORY(values);

    if (code == 200) {
      message.success(msg);
      setVisibleInfo({});
      getList(pagination);
    } else {
      message.error(msg);
    }
    setVisibleCreate(false);
  };
  const onRemove = async (values) => {
    // console.log(values)
    const {
      data: { code, data, msg },
    } = await DEL_GOODS_CATEGORY(values);
    if (code == 200) {
      message.success("删除成功");
      getList(pagination);
    } else {
      message.error(msg);
    }
    setVisibleCreate(false);
  };

  const getList = async (paginations) => {
    const {
      data: { code, data, msg },
    } = await GET_GOODS_CATEGORY_LIST({ ...paginations });
    if (code == 200) {
      setDataSource(data.resource);
      setCategory(array2tree(data.resource, "id", "category_id"));
      setPagination({
        ...pagination,
        total: data.total,
      });
    }
  };
  const paginationChange = (pages) => {
    setPagination({
      ...pagination,
      pageNo: pages.current || 1,
      current: pages.current,
    });
    getList({
      ...pagination,
      pageNo: pages.current || 1,
      current: pages.current,
    });
    // console.log("pageNo: ", pages);
  };

  useEffect(() => {
    getList(pagination);
  }, []);

  // useEffect(() => {
  //   console.log(category);
  // }, [category]);
  return (
    <div className="goods-storage">
      <div className="btn-box">
        <Button
          type="primary"
          className="mr-3"
          onClick={() => {
            setVisibleCreate(true);
          }}
        >
          创建商品分类
        </Button>
      </div>
      <div className="table-action"></div>
      <Table
        rowKey={"id"}
        columns={columns}
        dataSource={category}
        expandable={{
          defaultExpandAllRows: true,
        }}
        pagination={{
          pageSize: pagination.pageSize,
          total: pagination.total,
        }}
        onChange={paginationChange}
      />

      {/* 创建 */}
      {visibleCreate && (
        <CreateShopForm
          visible={visibleCreate}
          info={visibleInfo}
          onCreate={onCreate}
          onCancel={() => {
            setVisibleCreate(false);
            setVisibleInfo({});
          }}
          category={dataSource}
        />
      )}
    </div>
  );
};

export default Category;
