import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Route, Redirect, Link, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Table,
  Form,
  Input,
  Switch,
  Checkbox,
  Button,
  Modal,
  Cascader,
  message,
  Slider,
  InputNumber,
  Tag,
  Upload,
} from "antd";
import cookie from "react-cookies";
import "./list.less";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";
import qs from "qs";
import { GET_FINANCIAL_LIST, GET_FINANCIAL_DETAIL } from "../../api/financial";

const List = () => {
  const [filterForm] = Form.useForm();
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNo: 1,
    total: 0,
  });
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [visibleInfo, setVisibleInfo] = useState({});

  const [dataSource, setDataSource] = useState([]);
  const { pathname } = useLocation();
  const columns = [
    {
      title: "创建时间",
      dataIndex: "create_time",
      key: "create_time",
      render: (text, data) => moment(text).format("YYYY/MM/DD HH:mm:ss"),
    },
    {
      title: "类型",
      dataIndex: "type",
      key: "type",
      render: (text, data) =>
        (text == "1" && "积分") ||
        (text == "2" && "保证金") ||
        (text == "3" && "奖励余额") ||
        "-",
    },
    {
      title: "被操作用户",
      dataIndex: ["user", "nickname"],
      key: "user_id",
      render: (text, data) => (
        <div>
          <div>ID：{data.user.id}</div>
          <div>昵称：{data.user.nickname}</div>
          <div>手机号：{data.user.mobile}</div>
        </div>
      ),
    },
    {
      title: "渠道",
      dataIndex: "channel",
      key: "channel",
      render: (text, data) =>
        (text == "1" && "余额") ||
        (text == "2" && "支付宝") ||
        (text == "3" && "微信") ||
        (text == "4" && "积分") ||
        "-",
    },
    {
      title: "操作类型",
      dataIndex: "action",
      key: "action",
      render: (text, data) =>
        (text == "1" && "增加") || (text == "2" && "减少") || "-",
    },
    {
      title: "操作积分/金额",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "操作后积分/金额",
      dataIndex: "result",
      key: "result",
    },
    {
      title: "说明",
      dataIndex: "tips",
      key: "tips",
    },
    {
      title: "内部说明",
      dataIndex: "sys_tips",
      key: "sys_tips",
    },
  ];

  const getList = async (paginations) => {
    const {
      data: { code, data, msg },
    } = await GET_FINANCIAL_LIST({ ...paginations });
    if (code == 200) {
      setDataSource(data.resource);
      setPagination({
        ...pagination,
        total: data.total,
      });
    }
  };
  const paginationChange = (pages) => {
    setPagination({
      ...pagination,
      pageNo: pages.current || 1,
      current: pages.current,
    });
    getList({
      ...pagination,
      pageNo: pages.current || 1,
      current: pages.current,
    });
    // console.log("pageNo: ", pages);
  };

  useEffect(() => {
    getList(pagination);
  }, []);

  return (
    <div className="goods-storage">
      {/* <div className="btn-box">
        <Button
          type="primary"
          className="mr-3"
          onClick={() => {
            setVisibleCreate(true);
          }}
        >
          创建等级
        </Button>
      </div> */}
      <div className="table-action"></div>
      <Table
        rowKey={"id"}
        columns={columns}
        dataSource={dataSource}
        pagination={{
          pageSize: pagination.pageSize,
          total: pagination.total,
        }}
        onChange={paginationChange}
      />
    </div>
  );
};

export default List;
