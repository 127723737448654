import React from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import { Spin, Layout, Card, Menu, Button, Dropdown } from "antd";
import {
  DownOutlined,
  BarChartOutlined,
  CloudOutlined,
  ShopOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { GET_ADMIN_INFO, REFRESH_ADMIN_TOKEN, LOGIN_OUT } from "../api/login";

import cookie from "react-cookies";
import "./index.less";

import ContHeader from "../components/ContHeader";
import ContBreadcrumb from "../components/ContBreadcrumb";
//公共函数
import utils from "../assets/js/public";
import basicRouters from "../assets/json/basicRouters.json";

//页面引入
// import Overview from "./overview/list.js"; //概览
import LevelList from "./level/list"; //用户等级
import AgentList from "./agent/list"; //用户代理商
import UserList from "./user/list"; //用户管理
import FinancialList from "./financial/list"; //财务管理
import CouponList from "./coupon/list"; //优惠券管理
import ApplicationType from "./application/type"; //应用类型
import ApplicationClassify from "./application/classify"; //应用分类
import ApplicationList from "./application/list"; //应用列表
import FeedbackList from "./feedback/list"; //用户反馈
import VersionList from "./version/list"; //版本管理
import VersionProduct from "./version/product"; //版本产品
import VersionModel from "./version/model"; //版本型号
import VersionGuide from "./version/guide"; //版本引导
import VersionImage from "./version/image"; //版本镜像
import VersionDocs from "./version/docs"; //版本文档
import InstallClassify from "./install/classify"; //安装包分类
import InstallList from "./install/list"; //安装包列表
import GoodsCategory from "./goods/category"; //商品分类
import GoodsList from "./goods/list"; //商品列表
import OrderList from "./order/list"; //订单列表
import SalesChannel from "./saleschannel/list"; // 销售渠道管理
import SettingList from "./setting/list"; //系统配置列表

import PageList from "./page/list"; //页面列表
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

class Minishop extends React.Component {
  state = {
    loading: false,
    user_data: "",
    mode: "inline",
    theme: "light",
    routerList: [],
    routerListChild: [],
    ruleList: [],
    shopInfo: {},
    userInfo: {},
  };

  componentWillUnmount() {
    this.setState = () => false;
  }
  async componentDidUpdate() {
    await this.isLogin();
  }

  componentDidMount = async () => {
    await this.isLogin();
    await this.getUserInfo();
  };
  async isLogin() {
    if (!cookie.load("accessToken")) {
      cookie.remove("accessToken", { path: "/" });
      localStorage.removeItem("userInfo");
      this.props.history.push("/login");
      return true;
    }
    return false;
  }
  getUserInfo = async () => {
    const {
      data: { data, code, msg },
    } = await GET_ADMIN_INFO();
    if (code == 200) {
      // console.log(res.data)
      this.setState({
        userInfo: data,
      });
      localStorage.setItem("userInfo", JSON.stringify(data));
    }
  };

  userItems = [
    {
      key: 0,
      label: (
        <Button
          type="link"
          className="p-0 text-gray"
          onClick={() => {
            localStorage.removeItem("userInfo");
            cookie.remove("accessToken");
            cookie.remove("accessToken", { path: "/" });
            cookie.remove("accessToken", { path: "*" });
            cookie.remove("userInfo");
            cookie.remove("userInfo", { path: "/" });
            cookie.remove("userInfo", { path: "*" });
            this.props.history.push("/login");
          }}
        >
          退出
        </Button>
      ),
    },
  ];

  menus = [
    // {
    //   label: <Link to="/index/overview">概览</Link>,
    //   key: "overflow",
    //   icon: <UserOutlined />,
    // },
    {
      label: "用户管理",
      key: "user",
      icon: <UserOutlined />,
      children: [
        {
          label: <Link to="/index/level/list">用户等级</Link>,
          key: "userlevel",
        },
        {
          label: <Link to="/index/agent/list">用户代理商等级</Link>,
          key: "useragent",
        },
        {
          label: <Link to="/index/user/list">用户列表</Link>,
          key: "userlist",
        },
      ],
    },
    {
      label: <Link to="/index/order/list">订单管理</Link>,
      key: "order",
      icon: <UserOutlined />,
    },
    {
      label: <Link to="/index/financial/list">财务管理</Link>,
      key: "financial",
      icon: <UserOutlined />,
    },
    {
      label: "优惠券管理",
      key: "coupon",
      icon: <UserOutlined />,
      children: [
        {
          label: <Link to="/index/coupon/list">优惠券列表</Link>,
          key: "couponlist",
        },
        {
          label: <Link to="/index/coupon/get">用户领取列表</Link>,
          key: "couponget",
        },
        {
          label: <Link to="/index/coupon/use">用户使用列表</Link>,
          key: "couponuse",
        },
      ],
    },
    {
      label: "应用管理",
      key: "application",
      icon: <ShopOutlined />,
      children: [
        {
          label: <Link to="/index/application/list">应用列表</Link>,
          key: "applicationlist",
        },
        {
          label: <Link to="/index/application/type">应用类型</Link>,
          key: "applicationtype",
        },
        {
          label: <Link to="/index/application/classify">应用分类</Link>,
          key: "applicationclassify",
        },
      ],
    },
    {
      label: <Link to="/index/feedback/list">用户反馈</Link>,
      key: "feedback",
      icon: <UserOutlined />,
    },
    // {
    //   label: <Link to="/index/version/list">版本管理</Link>,
    //   key: "version",
    //   icon: <UserOutlined />,
    // },
    {
      label: "版本管理",
      key: "version",
      icon: <ShopOutlined />,
      children: [
        {
          label: <Link to="/index/version/product">产品列表</Link>,
          key: "versionproduct",
        },
        {
          label: <Link to="/index/version/model">型号列表</Link>,
          key: "versionmodel",
        },
        {
          label: <Link to="/index/version/guide">引导列表</Link>,
          key: "versionguide",
        },
        {
          label: <Link to="/index/version/image">镜像列表</Link>,
          key: "versionimage",
        },
        {
          label: <Link to="/index/version/docs">文档列表</Link>,
          key: "versiondocs",
        },
      ],
    },
    {
      label: "安装包管理",
      key: "install",
      icon: <ShopOutlined />,
      children: [
        {
          label: <Link to="/index/install/list">安装包列表</Link>,
          key: "installlist",
        },
        {
          label: <Link to="/index/install/classify">安装包分类</Link>,
          key: "installclassify",
        },
      ],
    },
    {
      label: "商品管理",
      key: "goods",
      icon: <ShopOutlined />,
      children: [
        {
          label: <Link to="/index/goods/category">商品分类</Link>,
          key: "goodscategory",
        },
        {
          label: <Link to="/index/goods/list">商品列表</Link>,
          key: "goodslist",
        },
      ],
    },
    {
      label: <Link to="/index/saleschannel/list">销售渠道</Link>,
      key: "saleschannel",
      icon: <UserOutlined />,
    },
    {
      label: <Link to="/index/setting/list">系统参数</Link>,
      key: "setting",
      icon: <UserOutlined />,
    },
  ];
  render() {
    const { loading, shopInfo, userInfo } = this.state;
    return (
      <Spin spinning={loading}>
        <Layout hasSider>
          <Sider
            style={{
              overflow: "auto",
              height: "100vh",
              position: "fixed",
              left: 0,
              top: 0,
              bottom: 0,
            }}
          >
            <div
              className="logo"
              style={{ padding: "15px 0", textAlign: "center" }}
            >
              <img src={require("../assets/image/logo.png").default} />
            </div>

            <Menu
              theme="dark"
              mode="inline"
              // openKeys={["6"]}
              defaultSelectedKeys={["userlist"]}
              items={[...this.menus]}
            />
          </Sider>
          <Layout className="site-layout" style={{ marginLeft: 200 }}>
            <Header
              className="site-layout-background"
              style={{ padding: 0, background: "#fff" }}
            >
              <div className="d-flex justify-content-between align-item-center px-3">
                <div></div>
                <div>
                  <Dropdown
                    menu={{
                      items: this.userItems,
                    }}
                  >
                    <div style={{ cursor: "pointer" }}>
                      {(userInfo && userInfo.nickname) || "-"}
                    </div>
                  </Dropdown>
                </div>
              </div>
            </Header>
            <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
              <Switch>
                {/* 概览 */}
                {/* <Route path="/index/overview" exact component={Overview} /> */}
                {/* 用户等级 */}
                <Route path="/index/level/list" exact component={LevelList} />
                {/* 用户代理商 */}
                <Route path="/index/agent/list" exact component={AgentList} />
                {/* 用户管理 */}
                <Route path="/index/user/list" exact component={UserList} />
                {/* 订单管理 */}
                <Route path="/index/order/list" exact component={OrderList} />
                {/* 财务管理 */}
                <Route
                  path="/index/financial/list"
                  exact
                  component={FinancialList}
                />
                {/* 优惠券管理 */}
                <Route path="/index/coupon/list" exact component={CouponList} />
                {/* 应用管理 */}
                <Route
                  path="/index/application/type"
                  exact
                  component={ApplicationType}
                />
                <Route
                  path="/index/application/classify"
                  exact
                  component={ApplicationClassify}
                />
                <Route
                  path="/index/application/list"
                  exact
                  component={ApplicationList}
                />
                {/* 用户反馈 */}
                <Route
                  path="/index/feedback/list"
                  exact
                  component={FeedbackList}
                />
                {/* 版本管理 */}
                <Route
                  path="/index/version/list"
                  exact
                  component={VersionList}
                />

                <Route
                  path="/index/version/product"
                  exact
                  component={VersionProduct}
                />

                <Route
                  path="/index/version/model"
                  exact
                  component={VersionModel}
                />

                <Route
                  path="/index/version/guide"
                  exact
                  component={VersionGuide}
                />

                <Route
                  path="/index/version/image"
                  exact
                  component={VersionImage}
                />

                <Route
                  path="/index/version/docs"
                  exact
                  component={VersionDocs}
                />
                {/* 安装包管理 */}
                <Route
                  path="/index/install/classify"
                  exact
                  component={InstallClassify}
                />
                <Route
                  path="/index/install/list"
                  exact
                  component={InstallList}
                />
                {/* 商品管理 */}
                <Route
                  path="/index/goods/category"
                  exact
                  component={GoodsCategory}
                />
                <Route path="/index/goods/list" exact component={GoodsList} />
                {/* 销售渠道管理 */}
                <Route
                  path="/index/saleschannel/list"
                  exact
                  component={SalesChannel}
                />
                {/* 系统参数配置 */}
                <Route
                  path="/index/setting/list"
                  exact
                  component={SettingList}
                />
                {/* 页面 */}
                <Route path="/index/page/edit" exact component={PageList} />
                <Redirect to={`/index/user/list`} />
              </Switch>
            </Content>
            <Footer style={{ textAlign: "center", background: "transparent" }}>
              Copyright © 2022 宿州七朵信息技术有限公司 沪ICP备2020033741号-1
            </Footer>
          </Layout>
        </Layout>
      </Spin>
    );
  }
}

export default Minishop;
