import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { Route, Redirect, Link, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Table,
  Form,
  Input,
  Switch,
  Checkbox,
  Button,
  Modal,
  Cascader,
  message,
  Slider,
  InputNumber,
  Tag,
  Upload,
  Select,
  Space,
} from "antd";
import cookie from "react-cookies";
import "./list.less";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from "quill-image-resize-module-react";
// 使用前需注册
Quill.register("modules/imageResize", ImageResize); // 注册图片缩放

import "react-quill/dist/quill.snow.css";
import moment from "moment";
import qs from "qs";
import _ from "lodash";
import { array2tree, findParentIds } from "../../common/utils";
import { UPLOAD_FILE } from "../../api/common";
import { GET_GOODS_CATEGORY_LIST } from "../../api/goodsCategory";
import { GET_LEVEL_LIST } from "../../api/level";
import {
  GET_GOODS_LIST,
  GET_GOODS_DETAIL,
  SET_GOODS,
  DEL_GOODS,
} from "../../api/goods";

import Sku from "./sku"; // 规格组件

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const beforeUpload = (file) => {
  const isLt2M = file.size / 1024 / 1024 < 200;
  if (!isLt2M) {
    message.error("文件大于200MB!");
  }
  return isLt2M;
};

const CreateShopForm = ({
  visible,
  info = {},
  onCreate,
  onCancel,
  classify,
  category,
}) => {
  //这是点击图片图标触发的事件
  const imageHandler = async (type) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.setAttribute("multiple", "multiple");
    input.click();
    const that = this;
    input.onchange = async () => {
      Array.from(input.files).forEach(async (item) => {
        //很很很很重要的一步
        const formData = new FormData();
        formData.append("file", item, item.name);

        const {
          data: { data, msg, code },
        } = await UPLOAD_FILE(formData);

        if (code == 200) {
          let quill =
            (type == "pc" && pcQuill.current?.getEditor()) ||
            (type == "mob" && mobQuill.current?.getEditor()) ||
            ""; //获取到编辑器本身
          const cursorPosition = quill.getSelection().index; //获取当前光标位置
          const link = data;
          quill.insertEmbed(cursorPosition, "image", link); //插入图片
          quill.setSelection(cursorPosition + 1); //光标位置加1
        }
      });
    };
  };

  // 编辑器使用配置
  const pcQuill = useRef(null);
  const [modules, setModules] = useState({
    toolbar: {
      container: [
        ["bold", "italic", "underline", "strike"], // 加粗，斜体，下划线，删除线
        ["blockquote", "code-block"], // 字体样式
        ["link", "image"], // 上传图片、上传视频
        [{ list: "ordered" }, { list: "bullet" }], // 有序列表，无序列表
        [{ script: "sub" }, { script: "super" }], // 下角标，上角标
        // [{ indent: '-1' }, { indent: '+1' }], // 缩进
        [{ align: [] }], // 居中
        // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ color: [] }, { background: [] }], // 文字颜色、背景颜色选择
        [{ direction: "rtl" }], // 文字输入方向
        [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
        [{ lineheight: ["1", "1.5", "1.75", "2", "3", "4", "5"] }], // 自定义行高
        ["clean"], // 清除样式
      ], //如果不自定义上传下面的可以不要

      handlers: {
        //点击图片标志会调用的方法
        image: (e) => {
          imageHandler("pc");
        },
      },
      imageResize: {
        // 调整图片尺寸
        displayStyles: {
          border: "none",
        },
        modules: ["Resize", "DisplaySize", "Toolbar"],
      },
    },
  });

  const mobQuill = useRef(null);
  const [modules2, setModules2] = useState({
    toolbar: {
      container: [
        ["bold", "italic", "underline", "strike"], // 加粗，斜体，下划线，删除线
        ["blockquote", "code-block"], // 字体样式
        ["link", "image"], // 上传图片、上传视频
        [{ list: "ordered" }, { list: "bullet" }], // 有序列表，无序列表
        [{ script: "sub" }, { script: "super" }], // 下角标，上角标
        // [{ indent: '-1' }, { indent: '+1' }], // 缩进
        [{ align: [] }], // 居中
        // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ color: [] }, { background: [] }], // 文字颜色、背景颜色选择
        [{ direction: "rtl" }], // 文字输入方向
        [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
        [{ lineheight: ["1", "1.5", "1.75", "2", "3", "4", "5"] }], // 自定义行高
        ["clean"], // 清除样式
      ], //如果不自定义上传下面的可以不要

      handlers: {
        //点击图片标志会调用的方法
        image: (e) => {
          imageHandler("mob");
        },
      },
      imageResize: {
        // 调整图片尺寸
        displayStyles: {
          border: "none",
        },
        modules: ["Resize", "DisplaySize", "Toolbar"],
      },
    },
  });

  const [form] = Form.useForm();
  const [formVals, setFormVals] = useState({});
  const [type, setType] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  // 会员等级
  const [level, setLevel] = useState([]);

  // 封面
  const [coverFile, setCoverFile] = useState([]);
  // 视频
  const [videoFile, setVideoFile] = useState([]);
  // 大图
  const [images, setImages] = useState([]);

  // sku 默认配置
  const [submitList, setSubmitList] = useState([]);
  const [tableData, setTableData] = useState([]); // 显示的数据数量

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        上传
      </div>
    </div>
  );

  useEffect(() => {
    if (visible) {
      getLevel();
      form.resetFields();

      info = {
        // state: false,
        ...info,
      };

      if (info) {
        if (!!info?.sku_json) {
          setTableData(JSON.parse(info.sku_json));
        }
        if (!!info?.sku?.length) {
          setSubmitList([
            ...info.sku.map((item) => ({
              ...JSON.parse(item.sku),
              id: item.id,
            })),
          ]);
        }
        // console.log(info.category_id, category);
        if (!!info.category_id) {
          info.category_id = findParentIds(category, info.category_id);
        }

        info.cover && setCoverFile([info.cover]);
        info.video && setVideoFile([info.video]);
        info.images &&
          setImages([
            ...info.images?.split(",")?.map((item, ind) => ({
              uid: ind,
              name: item,
              status: "done",
              url: item,
              thumbUrl: item,
            })),
          ]);

        form.setFieldsValue({
          ...info,
        });
        setFormVals({ ...info });
      }
    }
  }, [visible]);

  // 获取会员等级
  const getLevel = async () => {
    const {
      data: { data, code, msg },
    } = await GET_LEVEL_LIST({
      pageSize: 99999,
      pageNo: 1,
    });

    if (code == 200) {
      setLevel(data.resource);
    }
  };

  return (
    <Modal
      getContainer={false}
      open={visible}
      width={"100%"}
      footer={false}
      okText={"保存"}
      cancelText={"取消"}
      onOk={() => {}}
      onCancel={onCancel}
      destroyOnClose
      className="modal-createshop full-modal"
      centered
    >
      <Row gutter={30}>
        <Col span={24}>
          <div className="title">
            <h1>{info.id ? "编辑" : "创建"}商品</h1>
          </div>
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              ...info,
            }}
            name="form_in_modal"
            onValuesChange={(vals, allVals) => {
              // console.log(allVals)
              setFormVals(allVals);
            }}
          >
            <div>
              <Form.Item name="id" hidden>
                <Input />
              </Form.Item>
              <Row gutter={30}>
                <Col span={6}>
                  <Form.Item name="promotion_title" label="促销标题">
                    <Input />
                  </Form.Item>
                  <Form.Item name="name" label="商品名称">
                    <Input />
                  </Form.Item>
                  <Form.Item name="category_id" label="商品分类">
                    <Cascader options={[...category]} />
                  </Form.Item>
                  <Form.Item name="intro" label="商品描述">
                    <Input.TextArea rows={3} />
                  </Form.Item>
                  <Form.Item name="cover" label="封面">
                    <Upload
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      customRequest={async (params) => {
                        setLoading(true);
                        const { file, onSuccess, onError } = params;

                        if (file.size >= 2 * 1024 * 1024) {
                          message.error("文件大小不能超过 2M");
                          return false;
                        }

                        const formData = new FormData();
                        formData.append("file", file);

                        const {
                          data: { data, msg, code },
                        } = await UPLOAD_FILE(formData);

                        if (code == 200) {
                          setCoverFile([data]);
                        }
                        console.log(data);
                        setLoading(false);
                      }}
                      beforeUpload={beforeUpload}
                    >
                      {coverFile[0] ? (
                        <img
                          src={coverFile[0]}
                          alt="avatar"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                          }}
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  </Form.Item>
                  <Form.Item name="video" label="视频">
                    <Upload
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      customRequest={async (params) => {
                        setLoading(true);
                        const { file, onSuccess, onError } = params;

                        if (file.size >= 2 * 1024 * 1024) {
                          message.error("文件大小不能超过 2M");
                          return false;
                        }

                        const formData = new FormData();
                        formData.append("file", file);

                        const {
                          data: { data, msg, code },
                        } = await UPLOAD_FILE(formData);

                        if (code == 200) {
                          setVideoFile([data]);
                        }
                        console.log(data);
                        setLoading(false);
                      }}
                      beforeUpload={beforeUpload}
                    >
                      {videoFile[0] ? (
                        <video
                          src={videoFile[0]}
                          alt="avatar"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                          }}
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  </Form.Item>
                  <Form.Item name="images" label="商品多图">
                    <Upload
                      listType="picture"
                      fileList={[...images]}
                      customRequest={async (params) => {
                        const { file, onSuccess, onError } = params;

                        if (file.size >= 2 * 1024 * 1024) {
                          message.error("文件大小不能超过 2M");
                          return false;
                        }

                        const formData = new FormData();
                        formData.append("file", file);

                        const {
                          data: { data, msg, code },
                        } = await UPLOAD_FILE(formData);

                        if (code == 200) {
                          setImages([
                            ...images,
                            {
                              uid: images.length,
                              name: data,
                              status: "done",
                              url: data,
                              thumbUrl: data,
                            },
                          ]);
                        }
                        console.log(data);
                      }}
                      onRemove={(file) => {
                        setImages([
                          ...images.filter((item) => item.uid != file.uid),
                        ]);
                      }}
                      beforeUpload={beforeUpload}
                    >
                      <Button icon={<UploadOutlined />}>上传</Button>
                    </Upload>
                  </Form.Item>
                  <Form.Item name="vip_level_id" label="会员等级限制">
                    <Select
                      options={[
                        {
                          label: "不限制",
                          value: 0,
                        },
                        ...level.map((item) => ({
                          label: item.name,
                          value: item.id,
                        })),
                      ]}
                    />
                  </Form.Item>
                  <Form.Item name="unit" label="商品单位">
                    <Input />
                  </Form.Item>
                  <Form.Item name="is_free_shipping" label="是否包邮">
                    <Switch checked={formVals.is_free_shipping} />
                  </Form.Item>
                  {(!formVals.is_free_shipping && (
                    <Form.Item name="postage" label="邮费">
                      <InputNumber />
                    </Form.Item>
                  )) ||
                    ""}
                  <Form.Item name="logistics_tips" label="物流备注">
                    <Input />
                  </Form.Item>
                  <Form.Item name="state" label="商品状态">
                    <Switch checked={formVals.state} />
                  </Form.Item>
                </Col>
                <Col span={18}>
                  <Form.Item name="sku">
                    <Sku
                      submitList={submitList}
                      setSubmitList={setSubmitList}
                      tableData={tableData}
                      setTableData={setTableData}
                    />
                  </Form.Item>
                  <Form.Item label="规格介绍" style={{ width: "100%" }}>
                    <Form.List name="sku_intro">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <div
                              key={key}
                              style={{
                                display: "flex",
                                marginBottom: 8,
                              }}
                            >
                              <Form.Item
                                {...restField}
                                name={[name, "label"]}
                                label="标题"
                                style={{ width: "20%", marginRight: "15px" }}
                              >
                                <Input placeholder="请输入规格介绍标题" />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, "value"]}
                                label="值"
                                style={{ width: "20%", marginRight: "15px" }}
                              >
                                <Input.TextArea placeholder="请输入规格介绍值" />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, "tips"]}
                                label="提示"
                                style={{ width: "30%" }}
                              >
                                <Input.TextArea placeholder="请输入规格介绍提示" rows={3} />
                              </Form.Item>
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                              />
                            </div>
                          ))}
                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                            >
                              增加
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Form.Item>
                  <Form.Item name="pc_detail" label="pc端商品详情">
                    <ReactQuill ref={pcQuill} height={200} modules={modules} />
                  </Form.Item>
                  <Form.Item name="mob_detail" label="移动端商品详情">
                    <ReactQuill
                      ref={mobQuill}
                      height={200}
                      modules={modules2}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Form>
          <div className="mt-3">
            <Button
              type="primary"
              size="large"
              className="py-3 px-5"
              style={{ height: "auto", lineHeight: "1" }}
              onClick={() => {
                let params = { ...form.getFieldsValue() };
                params.category_id =
                  params.category_id[params.category_id.length - 1];
                params.cover = coverFile[0];
                params.video = videoFile[0];
                params.images = [...images.map((item) => item.url)].join(",");
                params.sku = [
                  ...submitList.map((item) => ({
                    ...item,
                    sku: JSON.stringify(item),
                  })),
                ];
                params.sku_json = JSON.stringify([...tableData]);
                if (params.sku_intro.length) {
                  params.sku_intro = JSON.stringify(params.sku_intro);
                } else {
                  params.sku_intro = "";
                }

                console.log(params);
                console.log(tableData);
                // return;
                onCreate(params);
              }}
            >
              {info.id ? "编辑" : "创建"}商品
            </Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

const List = () => {
  const [filterForm] = Form.useForm();
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNo: 1,
    total: 0,
  });
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [visibleInfo, setVisibleInfo] = useState({});

  const [dataSource, setDataSource] = useState([]);
  // 分类线
  const [classify, setClassify] = useState([]);
  // 分类级联
  const [category, setCategory] = useState([]);

  const { pathname } = useLocation();
  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "商品名称",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "商品信息",
      dataIndex: "author",
      key: "author",
      width: 500,
      render: (text, data) => (
        <div>
          <div>{data.intro}</div>
        </div>
      ),
    },
    {
      title: "商品分类",
      dataIndex: ["category", "name"],
      key: "category_id",
    },
    {
      title: "限制会员等级",
      dataIndex: ["vipLevel", "name"],
      key: "vip_level_id",
      render: (text, data) => (!text && "不限制") || text,
    },
    {
      title: "是否包邮",
      dataIndex: "is_free_shipping",
      key: "is_free_shipping",
      render: (text, data) => (text == 0 && "否") || (text == 1 && "是") || "-",
    },
    {
      title: "状态",
      dataIndex: "state",
      key: "state",
      render: (text, data) =>
        (text == 0 && "下架") || (text == 1 && "上架") || "-",
    },
    {
      title: "总库存",
      dataIndex: "allStock",
      key: "allStock",
    },
    {
      title: "总销量",
      dataIndex: "allSale",
      key: "allSale",
    },
    {
      title: "创建时间",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, data) => moment(text).format("YYYY/MM/DD HH:mm:ss"),
    },
    {
      title: "操作",
      key: "action",
      render: (text, data) => {
        return (
          <div>
            <Button
              type="link"
              size="small"
              onClick={() => {
                setVisibleCreate(true);
                setVisibleInfo({
                  ...data,
                  sku_intro: data.sku_intro ? JSON.parse(data.sku_intro) : "",
                });
              }}
            >
              编辑
            </Button>
            <span>|</span>
            <Button
              type="link"
              danger
              size="small"
              onClick={() => {
                Modal.confirm({
                  title: "确定要删除该项吗?",
                  icon: <ExclamationCircleOutlined />,
                  content: "删除后将无法寻回，确认吗?",
                  onOk() {
                    onRemove({
                      id: data.id,
                    });
                  },
                  onCancel() {},
                });
              }}
            >
              删除
            </Button>
          </div>
        );
      },
    },
  ];

  // 获取商品分类
  const getClassify = async () => {
    const {
      data: { data, code, msg },
    } = await GET_GOODS_CATEGORY_LIST({
      pageSize: 99999,
      pageNo: 1,
    });

    if (code == 200) {
      setClassify(data.resource);
      setCategory(
        array2tree(
          [
            ...data.resource.map((item) => ({
              ...item,
              label: item.name,
              value: item.id,
            })),
          ],
          "id",
          "category_id"
        )
      );
    }
  };
  const onCreate = async (values) => {
    // console.log(values)
    const {
      data: { code, data, msg },
    } = await SET_GOODS(values);

    if (code == 200) {
      message.success(msg);
      setVisibleInfo({});
      getList(pagination);
    } else {
      message.error(msg);
    }
    setVisibleCreate(false);
  };
  const onRemove = async (values) => {
    // console.log(values)
    const {
      data: { code, data, msg },
    } = await DEL_GOODS(values);

    if (code == 200) {
      message.success("删除成功");
      getList(pagination);
    } else {
      message.error(msg);
    }
    setVisibleCreate(false);
  };

  const getList = async (paginations) => {
    const {
      data: { code, data, msg },
    } = await GET_GOODS_LIST({ ...paginations });
    if (code == 200) {
      setDataSource(data.resource);
      setPagination({
        ...pagination,
        total: data.total,
      });
    }
  };
  const paginationChange = (pages) => {
    setPagination({
      ...pagination,
      pageNo: pages.current || 1,
      current: pages.current,
    });
    getList({
      ...pagination,
      pageNo: pages.current || 1,
      current: pages.current,
    });
    // console.log("pageNo: ", pages);
  };

  useEffect(() => {
    getClassify();
    getList(pagination);
  }, []);

  return (
    <div className="goods-storage">
      <div className="btn-box">
        <Button
          type="primary"
          className="mr-3"
          onClick={() => {
            setVisibleCreate(true);
          }}
        >
          创建商品
        </Button>
      </div>
      <div className="table-action"></div>
      <Table
        rowKey={"id"}
        columns={columns}
        dataSource={dataSource}
        pagination={{
          pageSize: pagination.pageSize,
          total: pagination.total,
        }}
        onChange={paginationChange}
      />

      {/* 创建 */}
      {visibleCreate && (
        <CreateShopForm
          visible={visibleCreate}
          info={visibleInfo}
          onCreate={onCreate}
          onCancel={() => {
            setVisibleCreate(false);
            setVisibleInfo({});
          }}
          classify={classify}
          category={category}
        />
      )}
    </div>
  );
};

export default List;
