import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Route, Redirect, Link, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Table,
  Form,
  Input,
  Switch,
  Radio,
  Select,
  Checkbox,
  Button,
  Modal,
  Cascader,
  message,
  Slider,
  InputNumber,
  Tag,
  Upload,
  DatePicker,
} from "antd";
import cookie from "react-cookies";
import "./list.less";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";
import qs from "qs";
import { GET_GOODS_LIST } from "../../api/goods";
import { GET_GOODS_CATEGORY_LIST } from "../../api/goodsCategory";
import {
  GET_COUPON_LIST,
  GET_COUPON_DETAIL,
  SET_COUPON,
  DEL_COUPON,
} from "../../api/coupon";

const CreateShopForm = ({ visible, info = {}, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  const [formVals, setFormVals] = useState({});
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [goodsList, setGoodsList] = useState([]);
  const [goodsCategoryList, setGoodsCategoryList] = useState([]);

  const getGoods = async () => {
    const {
      data: { code, data, msg },
    } = await GET_GOODS_LIST({
      pageNo: 1,
      pageSize: 999999,
    });

    if (200 === code) {
      setGoodsList([...data.resource]);
    }
  };

  const getGoodsCategory = async () => {
    const {
      data: { code, data, msg },
    } = await GET_GOODS_CATEGORY_LIST({
      pageNo: 1,
      pageSize: 999999,
    });

    if (200 === code) {
      setGoodsCategoryList([...data.resource]);
    }
  };

  useEffect(() => {
    if (visible) {
      form.resetFields();
      getGoods();
      getGoodsCategory();
      if (info) {
        form.setFieldsValue({
          use_range: "0",
          end_type: 1,
          state: true,
          ...info,
        });
        setFormVals({
          use_range: "0",
          end_type: 1,
          state: true,
          ...info,
        });
      }
    }
  }, [visible]);

  return (
    <Modal
      getContainer={false}
      open={visible}
      width={650}
      footer={false}
      okText={"保存"}
      cancelText={"取消"}
      onOk={() => {
        onCreate(form.getFieldsValue());
      }}
      onCancel={onCancel}
      destroyOnClose
      className="modal-createshop"
      centered
    >
      <Row gutter={30}>
        <Col span={24}>
          <div className="title">
            <h1>{info.id ? "编辑" : "创建"}优惠券</h1>
          </div>
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              use_range: "0",
              end_type: 1,
              state: true,
              ...info,
            }}
            name="form_in_modal"
            onValuesChange={(vals, allVals) => {
              // console.log(allVals)
              setFormVals(allVals);
            }}
          >
            <div>
              <Form.Item name="id" hidden>
                <Input />
              </Form.Item>
              <Form.Item name="title" label="优惠券名称">
                <Input />
              </Form.Item>
              <Form.Item name="use_range" label="使用范围">
                <Radio.Group
                  options={[
                    {
                      label: "无限制",
                      value: "0",
                    },
                    {
                      label: "购买商品",
                      value: "1",
                    },
                    {
                      label: "购买授权",
                      value: "2",
                    },
                  ]}
                  onChange={(e) => {
                    console.log(e.target.value);
                    if (e.target.value == 0 || e.target.value == 2) {
                      setFormVals({
                        ...formVals,
                        use_range: e.target.value,
                        use_goods_category: undefined,
                        use_goods: undefined,
                      });
                      form.setFieldsValue({
                        ...form.getFieldsValue(),
                        use_range: e.target.value,
                        use_goods_category: undefined,
                        use_goods: undefined,
                      });
                    } else {
                      setFormVals({
                        ...formVals,
                        use_range: e.target.value,
                      });
                      form.setFieldsValue({
                        ...form.getFieldsValue(),
                        use_range: e.target.value,
                      });
                    }
                  }}
                />
              </Form.Item>
              {formVals.use_range == "1" && (
                <div>
                  <Form.Item name="use_goods_category" label="可用商品分类">
                    <Select
                      mode="multiple"
                      allowClear
                      options={[...goodsCategoryList].map((item) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                    />
                  </Form.Item>
                  <Form.Item name="use_goods" label="可用商品">
                    <Select
                      mode="multiple"
                      allowClear
                      options={[...goodsList].map((item) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                    />
                  </Form.Item>
                </div>
              )}
              <Form.Item name="threshold_amount" label="门槛金额">
                <InputNumber />
              </Form.Item>
              <Form.Item name="amount" label="优惠金额">
                <InputNumber />
              </Form.Item>
              <Form.Item name="num" label="发放数量">
                <InputNumber />
              </Form.Item>
              <Form.Item name="quantity" label="每人可领取数量">
                <InputNumber />
              </Form.Item>
              <Form.Item name="end_type" label="有效期类型">
                <Radio.Group
                  options={[
                    {
                      label: "固定有效期",
                      value: 1,
                    },
                    {
                      label: "领取后有效",
                      value: 2,
                    },
                  ]}
                  onChange={(e) => {
                    if (e.target.value == 1) {
                      setFormVals({
                        ...formVals,
                        end_type: e.target.value,
                        end_day: null,
                      });
                      form.setFieldsValue({
                        ...form.getFieldsValue(),
                        end_type: e.target.value,
                        end_day: null,
                      });
                    } else {
                      setFormVals({
                        ...formVals,
                        end_type: e.target.value,
                        end_time: undefined,
                      });
                      form.setFieldsValue({
                        ...form.getFieldsValue(),
                        end_type: e.target.value,
                        end_time: undefined,
                      });
                    }
                  }}
                />
              </Form.Item>
              {formVals.end_type == 1 && (
                <Form.Item name="end_time" label="优惠券结束时间">
                  <DatePicker />
                </Form.Item>
              )}
              {formVals.end_type == 2 && (
                <Form.Item name="end_day" label="领取后有效天数">
                  <InputNumber />
                </Form.Item>
              )}
              <Form.Item name="state" label="优惠券状态">
                <Switch checked={formVals.state} />
              </Form.Item>
            </div>
          </Form>
          <div className="mt-3">
            <Button
              type="primary"
              size="large"
              className="py-3 px-5"
              style={{ height: "auto", lineHeight: "1" }}
              onClick={() => {
                onCreate(form.getFieldsValue());
              }}
            >
              {info.id ? "编辑" : "创建"}优惠券
            </Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

const List = () => {
  const [filterForm] = Form.useForm();
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNo: 1,
    total: 0,
  });
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [visibleInfo, setVisibleInfo] = useState({});

  const [dataSource, setDataSource] = useState([]);
  const { pathname } = useLocation();
  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "优惠券名称",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "适用范围",
      dataIndex: "use_range",
      key: "use_range",
      render: (text, data) => (
        <div>
          <div className="mb-2">
            {(data.use_range == 0 && "无限制") ||
              (data.use_range == 1 && "购买商品") ||
              (data.use_range == 2 && "购买授权")}
          </div>
          {(data.use_range == 1 && (
            <div>
              <div className="mb-2">
                适用分类：
                {(data?.use_goods_category &&
                  data?.use_goods_category?.length &&
                  data.use_goods_category.map((item) => item.name).join(",")) ||
                  "-"}
              </div>
              <div>
                适用商品：
                {(data?.use_goods &&
                  data?.use_goods?.length &&
                  data.use_goods.map((item) => item.name).join(",")) ||
                  "-"}
              </div>
            </div>
          )) ||
            ""}
        </div>
      ),
    },
    {
      title: "门槛金额",
      dataIndex: "threshold_amount",
      key: "threshold_amount",
      render: (text, data) => "￥" + text,
    },
    {
      title: "优惠金额",
      dataIndex: "amount",
      key: "amount",
      render: (text, data) => "￥" + text,
    },
    {
      title: "剩余数量",
      dataIndex: "num",
      key: "num",
    },
    {
      title: "每人可领取数量",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "优惠券有效期",
      dataIndex: "end_day",
      key: "end_day",
      render: (text, data) => (
        <div>
          {(data?.end_day && `领取后 ${data.end_day} 天内有效`) ||
            `${data.end_time} 之前有效`}
        </div>
      ),
    },
    {
      title: "状态",
      dataIndex: "state",
      key: "state",
      render: (text, data) => (
        <div>
          {(text == 0 && (
            <Button type="link" danger onClick={() => onChangeState(data, 1)}>
              已禁用
            </Button>
          )) || (
            <Button type="link" onClick={() => onChangeState(data, 0)}>
              正常
            </Button>
          )}
        </div>
      ),
    },
    {
      title: "创建时间",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, data) => moment(text).format("YYYY/MM/DD HH:mm:ss"),
    },
    {
      title: "操作",
      key: "action",
      render: (text, data) => {
        return (
          <div>
            <Button
              type="link"
              danger
              size="small"
              onClick={() => {
                Modal.confirm({
                  title: "确定要删除该项吗?",
                  icon: <ExclamationCircleOutlined />,
                  content: "删除后将无法寻回，确认吗?",
                  onOk() {
                    onRemove({
                      id: data.id,
                    });
                  },
                  onCancel() {},
                });
              }}
            >
              删除
            </Button>
          </div>
        );
      },
    },
  ];

  const onCreate = async (values) => {
    console.log(values);
    // return;
    if (values.use_goods_category) {
      values.use_goods_category = values.use_goods_category.join(",");
    }
    if (values.use_goods) {
      values.use_goods = values.use_goods.join(",");
    }
    if (values.end_time) {
      values.end_time = moment(values.end_time).format("YYYY-MM-DD");
    }
    const {
      data: { code, data, msg },
    } = await SET_COUPON(values);

    if (code == 200) {
      message.success(msg);
      setVisibleInfo({});
      getList(pagination);
    } else {
      message.error(msg);
    }
    setVisibleCreate(false);
  };

  const onChangeState = async (info, state) => {
    console.log(info);
    const params = {
      id: info.id,
      state: state,
    };
    const {
      data: { code, data, msg },
    } = await SET_COUPON(params);

    if (code == 200) {
      message.success(msg);
      setVisibleInfo({});
      getList(pagination);
    } else {
      message.error(msg);
    }
    setVisibleCreate(false);
  };

  const onRemove = async (values) => {
    // console.log(values)
    const {
      data: { code, data, msg },
    } = await DEL_COUPON(values);
    if (code == 200) {
      message.success("删除成功");
      getList(pagination);
    } else {
      message.error(msg);
    }
    setVisibleCreate(false);
  };

  const getList = async (paginations) => {
    const {
      data: { code, data, msg },
    } = await GET_COUPON_LIST({ ...paginations });
    if (code == 200) {
      setDataSource(data.resource);
      setPagination({
        ...pagination,
        total: data.total,
      });
    }
  };
  const paginationChange = (pages) => {
    setPagination({
      ...pagination,
      pageNo: pages.current || 1,
      current: pages.current,
    });
    getList({
      ...pagination,
      pageNo: pages.current || 1,
      current: pages.current,
    });
    // console.log("pageNo: ", pages);
  };

  useEffect(() => {
    getList(pagination);
  }, []);

  return (
    <div className="goods-storage">
      <div className="btn-box">
        <Button
          type="primary"
          className="mr-3"
          onClick={() => {
            setVisibleCreate(true);
          }}
        >
          创建优惠券
        </Button>
      </div>
      <div className="table-action"></div>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={{
          pageSize: pagination.pageSize,
          total: pagination.total,
        }}
        onChange={paginationChange}
      />

      {/* 创建 */}
      {visibleCreate && (
        <CreateShopForm
          visible={visibleCreate}
          info={visibleInfo}
          onCreate={onCreate}
          onCancel={() => {
            setVisibleCreate(false);
            setVisibleInfo({});
          }}
        />
      )}
    </div>
  );
};

export default List;
