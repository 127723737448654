import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
import qs from "qs";

/**
 * 获取财务列表
 * @param {Object} parm 请求参数
 * @param {Number} parm.pageSize 每页数量
 * @param {Number} parm.pageNo 当前页码
 * @returns
 */
export const GET_FINANCIAL_LIST = async (parm) =>
  await instance.post(`${base}/admin/financial/list`, parm);

/**
 * 获取财务详情
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 等级项ID
 * @returns
 */
export const GET_FINANCIAL_DETAIL = async () =>
  await instance.get(`${base}/admin/financial/detail`);
