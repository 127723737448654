import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { Route, Redirect, Link, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Table,
  Form,
  Input,
  Switch,
  Checkbox,
  Button,
  Modal,
  Cascader,
  message,
  Slider,
  InputNumber,
  Tag,
  Upload,
  Select,
  Radio,
} from "antd";
import cookie from "react-cookies";
import "./list.less";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moment from "moment";
import qs from "qs";

import { VERSION_UPLOAD_FILE } from "../../api/common";
import {
  GET_VERSION_IMAGE_LIST,
  GET_VERSION_IMAGE_DETAIL,
  SET_VERSION_IMAGE,
  DEL_VERSION_IMAGE,
  GET_VERSION_MODEL_LIST,
} from "../../api/version";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const beforeUpload = (file) => {
  // const isLt2M = file.size / 1024 / 1024 < 200;
  // if (!isLt2M) {
  //   message.error("文件大于200MB!");
  // }
  // return isLt2M;
};

const CreateShopForm = ({ visible, info = {}, onCreate, onCancel, model }) => {
  const [form] = Form.useForm();
  const [formVals, setFormVals] = useState({});
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hisVersion, setHisVersion] = useState([]);

  // 文件包
  const [url, setUrl] = useState([]);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        上传
      </div>
    </div>
  );

  const getHis = async (modelId) => {
    const {
      data: { code, data, msg },
    } = await GET_VERSION_IMAGE_LIST({
      pageNo: 1,
      pageSize: 99999,
      where: {
        model_id: modelId,
        // now: 1,
      },
    });

    if (200 === code) {
      setHisVersion(data.resource);
    }
  };

  useEffect(() => {
    getHis(formVals.model_id);
  }, [formVals.model_id]);

  useEffect(() => {
    if (visible) {
      form.resetFields();

      info = {
        ...info,
        uptype: 1,
      };

      if (info) {
        info.url && setUrl([info.url]);

        if (info.prev_version_id) info.prev_version_id = [info.prev_version_id];
        form.setFieldsValue({
          ...info,
        });
        setFormVals(info);
      }
    }
  }, [visible]);

  return (
    <Modal
      getContainer={false}
      open={visible}
      width={650}
      footer={false}
      okText={"保存"}
      cancelText={"取消"}
      onOk={() => {
        onCreate(form.getFieldsValue());
      }}
      onCancel={onCancel}
      destroyOnClose
      className="modal-createshop"
      centered
    >
      <Row gutter={30}>
        <Col span={24}>
          <div className="title">
            <h1>{info.id ? "编辑" : "创建"}版本镜像</h1>
          </div>
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              ...info,
            }}
            name="form_in_modal"
            onValuesChange={(vals, allVals) => {
              console.log(allVals);
              setFormVals(allVals);
            }}
          >
            <div>
              <Form.Item name="id" hidden>
                <Input />
              </Form.Item>
              <Form.Item name="model_id" label="所属型号">
                <Select
                  options={model.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                />
              </Form.Item>
              <Form.Item name="name" label="版本号">
                <Input />
              </Form.Item>
              <Form.Item name="intro" label="版本镜像描述">
                <Input />
              </Form.Item>
              {/* <Form.Item name="logs" label="版本镜像记录">
                <ReactQuill height={200} />
              </Form.Item> */}
              <Form.Item name="uptype" label="文件地址类型">
                <Radio.Group
                  options={[
                    { label: "正常上传", value: 1 },
                    { label: "手动填写", value: 2 },
                  ]}
                />
              </Form.Item>
              {formVals.uptype == 1 && (
                <Form.Item name="url" label="版本镜像文件包">
                  <Upload
                    listType="picture"
                    maxCount={1}
                    className="avatar-uploader"
                    showUploadList={false}
                    customRequest={async (params) => {
                      setLoading(true);
                      const { file, onSuccess, onError } = params;
                      const formData = new FormData();
                      formData.append("file", file);

                      const {
                        data: { data, msg, code },
                      } = await VERSION_UPLOAD_FILE(formData);

                      if (code == 200) {
                        setUrl([data]);
                      }
                      // console.log(data);
                      setLoading(false);
                    }}
                    beforeUpload={beforeUpload}
                  >
                    {url[0] ? (
                      <div
                        className="file-box"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src={
                            require("../../assets/image/file-b-17.png").default
                          }
                          alt="file"
                          style={{
                            width: "80px",
                            marginRight: "15px",
                          }}
                        />
                        <div style={{ color: "#999" }}>{url[0]}</div>
                      </div>
                    ) : (
                      <Button icon={<UploadOutlined />}>上传</Button>
                    )}
                  </Upload>
                </Form.Item>
              )}

              {formVals.uptype == 2 && (
                <Form.Item name="url" label="版本镜像文件地址">
                  <Input />
                </Form.Item>
              )}
              <Form.Item name="prev_version_id" label="上一个版本">
                <Checkbox.Group
                  options={[
                    ...hisVersion.map((item) => ({
                      label: item.name,
                      value: item.id,
                    })),
                  ]}
                />
              </Form.Item>
              <Form.Item name="state" label="状态" valuePropName="checked">
                <Switch />
              </Form.Item>
            </div>
          </Form>
          <div className="mt-3">
            <Button
              type="primary"
              size="large"
              className="py-3 px-5"
              style={{ height: "auto", lineHeight: "1" }}
              onClick={() => {
                let params = { ...form.getFieldsValue() };
                if (formVals.uptype == 1) {
                  params.url = url[0];
                }
                delete params.uptype;
                params.prev_version_id = params.prev_version_id
                  ? params.prev_version_id.join(",")
                  : null;
                onCreate(params);
              }}
            >
              {info.id ? "编辑" : "创建"}版本镜像
            </Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

const List = () => {
  const [filterForm] = Form.useForm();
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNo: 1,
    total: 0,
  });
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [visibleInfo, setVisibleInfo] = useState({});

  const [dataSource, setDataSource] = useState([]);
  const [model, setModel] = useState([]);
  const { pathname } = useLocation();
  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "版本号",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "所属产品",
      dataIndex: ["product", "name"],
      key: ["product", "name"],
    },
    {
      title: "所属型号",
      dataIndex: ["model", "name"],
      key: ["model", "name"],
    },
    {
      title: "上一个版本",
      dataIndex: ["prevVersion", "name"],
      key: ["prevVersion", "name"],
    },
    {
      title: "下载地址",
      dataIndex: "url",
      key: "url",
    },
    {
      title: "状态",
      dataIndex: "stateEnum",
      key: "stateEnum",
    },
    {
      title: "创建时间",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, data) => moment(text).format("YYYY/MM/DD HH:mm:ss"),
    },
    {
      title: "修改时间",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text, data) => moment(text).format("YYYY/MM/DD HH:mm:ss"),
    },
    {
      title: "操作",
      key: "action",
      render: (text, data) => {
        return (
          <div>
            <Button
              type="link"
              size="small"
              onClick={() => {
                setVisibleCreate(true);
                setVisibleInfo({ ...data });
              }}
            >
              编辑
            </Button>
            <span>|</span>
            <Button
              type="link"
              danger
              size="small"
              onClick={() => {
                Modal.confirm({
                  title: "确定要删除该项吗?",
                  icon: <ExclamationCircleOutlined />,
                  content: "删除后将无法寻回，确认吗?",
                  onOk() {
                    onRemove({
                      id: data.id,
                    });
                  },
                  onCancel() {},
                });
              }}
            >
              删除
            </Button>
          </div>
        );
      },
    },
  ];

  const getModel = async () => {
    const {
      data: { code, data, msg },
    } = await GET_VERSION_MODEL_LIST({
      pageNo: 1,
      pageSize: 99999,
    });

    if (200 === code) {
      setModel(data.resource);
    }
  };

  const onCreate = async (values) => {
    // console.log(values)
    const {
      data: { code, data, msg },
    } = await SET_VERSION_IMAGE(values);

    if (code == 200) {
      message.success(msg);
      setVisibleInfo({});
      getList(pagination);
    } else {
      message.error(msg);
    }
    setVisibleCreate(false);
  };
  const onRemove = async (values) => {
    // console.log(values)
    const {
      data: { code, data, msg },
    } = await DEL_VERSION_IMAGE(values);
    if (code == 200) {
      message.success("删除成功");
      getList(pagination);
    } else {
      message.error(msg);
    }
    setVisibleCreate(false);
  };

  const getList = async (paginations) => {
    const {
      data: { code, data, msg },
    } = await GET_VERSION_IMAGE_LIST({ ...paginations });
    if (code == 200) {
      setDataSource(data.resource);
      setPagination({
        ...pagination,
        total: data.total,
      });
    }
  };
  const paginationChange = (pages) => {
    setPagination({
      ...pagination,
      pageNo: pages.current || 1,
      current: pages.current,
    });
    getList({
      ...pagination,
      pageNo: pages.current || 1,
      current: pages.current,
    });
    // console.log("pageNo: ", pages);
  };

  useEffect(() => {
    getList(pagination);
    getModel();
  }, []);

  return (
    <div className="goods-storage">
      <div className="btn-box">
        <Button
          type="primary"
          className="mr-3"
          onClick={() => {
            setVisibleCreate(true);
          }}
        >
          创建版本镜像
        </Button>
      </div>
      <div className="table-action"></div>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={{
          pageSize: pagination.pageSize,
          total: pagination.total,
        }}
        onChange={paginationChange}
      />

      {/* 创建 */}
      {visibleCreate && (
        <CreateShopForm
          visible={visibleCreate}
          info={visibleInfo}
          onCreate={onCreate}
          onCancel={() => {
            setVisibleCreate(false);
            setVisibleInfo({});
          }}
          model={model}
        />
      )}
    </div>
  );
};

export default List;
