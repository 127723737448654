import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
import qs from "qs";

/**
 * 获取安装包列表
 * @param {Object} parm 请求参数
 * @param {Number} parm.pageSize 每页数量
 * @param {Number} parm.pageNo 当前页码
 * @returns
 */
export const GET_INSTALL_LIST = async (parm) =>
  await instance.post(`${base}/admin/install/list`, parm);

/**
 * 获取安装包详情
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 等级项ID
 * @returns
 */
export const GET_INSTALL_DETAIL = async () =>
  await instance.get(`${base}/admin/install/detail`);

/**
 * 设置安装包
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 安装包项ID
 * @param {String} parm.versionNo 版本号
 * @param {String} parm.url 文件地址
 * @param {String} parm.intro 说明
 * @param {String} parm.date 日期
 * @param {String} parm.classify_id 所属分类ID
 * @returns
 */
export const SET_INSTALL = async (parm) =>
  await instance.post(`${base}/admin/install/set`, parm);

/**
 * 删除安装包
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 等级项ID
 * @returns
 */
export const DEL_INSTALL = async (parm) =>
  await instance.delete(`${base}/admin/install/del`, { data: { ...parm } });
