import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Route, Redirect, Link, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Table,
  Form,
  Input,
  Switch,
  Checkbox,
  Button,
  Modal,
  Cascader,
  message,
  Slider,
  InputNumber,
  Tag,
  Upload,
  Select,
  DatePicker,
} from "antd";
import cookie from "react-cookies";
import "./list.less";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import moment from "moment";
import dayjs from "dayjs";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import qs from "qs";
import { GET_INSTALL_CLASSIFY_LIST } from "../../api/installClassify";
import {
  GET_INSTALL_LIST,
  GET_INSTALL_DETAIL,
  SET_INSTALL,
  DEL_INSTALL,
} from "../../api/install";

const CreateShopForm = ({ visible, info = {}, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  const [formVals, setFormVals] = useState({});
  const [type, setType] = useState([]);
  const [classify, setClassify] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible) {
      getClassify();
      form.resetFields();

      info = {
        ...info,
      };
      console.log(info);
      if (info) {
        info.date = moment(info.date);

        form.setFieldsValue({
          ...info,
        });
        setFormVals({ ...info });
      }
    }
  }, [visible]);

  const getClassify = async () => {
    const {
      data: { data, code, msg },
    } = await GET_INSTALL_CLASSIFY_LIST({
      pageSize: 9999,
      pageNo: 1,
    });

    if (code == 200) {
      setClassify(data.resource);
    }
  };

  return (
    <Modal
      getContainer={false}
      open={visible}
      width={650}
      footer={false}
      okText={"保存"}
      cancelText={"取消"}
      onOk={() => {}}
      onCancel={onCancel}
      destroyOnClose
      className="modal-createshop"
      centered
    >
      <Row gutter={30}>
        <Col span={24}>
          <div className="title">
            <h1>{info.id ? "编辑" : "创建"}安装包</h1>
          </div>
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              ...info,
              date: moment(info.date),
            }}
            name="form_in_modal"
            onValuesChange={(vals, allVals) => {
              // console.log(allVals)
              setFormVals(allVals);
            }}
          >
            <div>
              <Form.Item name="id" hidden>
                <Input />
              </Form.Item>
              <Form.Item name="versionNo" label="版本号">
                <Input />
              </Form.Item>
              <Form.Item name="url" label="文件地址">
                <Input />
              </Form.Item>
              <Form.Item name="classify_id" label="安装包分类">
                <Select
                  options={classify.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                />
              </Form.Item>
              <Form.Item name="date" label="日期">
                <DatePicker showTime />
              </Form.Item>
              <Form.Item name="intro" label="说明">
                <ReactQuill height={200} />
              </Form.Item>
            </div>
          </Form>
          <div className="mt-3">
            <Button
              type="primary"
              size="large"
              className="py-3 px-5"
              style={{ height: "auto", lineHeight: "1" }}
              onClick={() => {
                let params = { ...form.getFieldsValue() };
                params.date = moment(params.date).format("YYYY-MM-DD HH:mm:ss");
                // console.log(iconFile[0]);
                // console.log(images);
                // console.log(install[0]);
                console.log(params);
                // return;
                onCreate(params);
              }}
            >
              {info.id ? "编辑" : "创建"}安装包
            </Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

const List = () => {
  const [filterForm] = Form.useForm();
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNo: 1,
    total: 0,
  });
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [visibleInfo, setVisibleInfo] = useState({});

  const [dataSource, setDataSource] = useState([]);
  const { pathname } = useLocation();
  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "安装包版本",
      dataIndex: "versionNo",
      key: "versionNo",
    },
    {
      title: "文件地址",
      dataIndex: "url",
      key: "url",
      width: 300,
    },
    {
      title: "安装包分类",
      dataIndex: ["classify", "name"],
      key: "classify_id",
    },
    {
      title: "日期",
      dataIndex: "date",
      key: "date",
      render: (text, data) => moment(text).format("YYYY/MM/DD HH:mm:ss"),
    },
    {
      title: "创建时间",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, data) => moment(text).format("YYYY/MM/DD HH:mm:ss"),
    },
    {
      title: "操作",
      key: "action",
      render: (text, data) => {
        return (
          <div>
            <Button
              type="link"
              size="small"
              onClick={() => {
                setVisibleCreate(true);
                setVisibleInfo({ ...data });
              }}
            >
              编辑
            </Button>
            <span>|</span>
            <Button
              type="link"
              danger
              size="small"
              onClick={() => {
                Modal.confirm({
                  title: "确定要删除该项吗?",
                  icon: <ExclamationCircleOutlined />,
                  content: "删除后将无法寻回，确认吗?",
                  onOk() {
                    onRemove({
                      id: data.id,
                    });
                  },
                  onCancel() {},
                });
              }}
            >
              删除
            </Button>
          </div>
        );
      },
    },
  ];

  const onCreate = async (values) => {
    // console.log(values)
    const {
      data: { code, data, msg },
    } = await SET_INSTALL(values);

    if (code == 200) {
      message.success(msg);
      setVisibleInfo({});
      getList(pagination);
    } else {
      message.error(msg);
    }
    setVisibleCreate(false);
  };
  const onRemove = async (values) => {
    // console.log(values)
    const {
      data: { code, data, msg },
    } = await DEL_INSTALL(values);
    if (code == 200) {
      message.success("删除成功");
      getList(pagination);
    } else {
      message.error(msg);
    }
    setVisibleCreate(false);
  };

  const getList = async (paginations) => {
    const {
      data: { code, data, msg },
    } = await GET_INSTALL_LIST({ ...paginations });
    if (code == 200) {
      setDataSource(data.resource);
      setPagination({
        ...pagination,
        total: data.total,
      });
    }
  };
  const paginationChange = (pages) => {
    setPagination({
      ...pagination,
      pageNo: pages.current || 1,
      current: pages.current,
    });
    getList({
      ...pagination,
      pageNo: pages.current || 1,
      current: pages.current,
    });
    // console.log("pageNo: ", pages);
  };

  useEffect(() => {
    getList(pagination);
  }, []);

  return (
    <div className="goods-storage">
      <div className="btn-box">
        <Button
          type="primary"
          className="mr-3"
          onClick={() => {
            setVisibleCreate(true);
          }}
        >
          创建安装包
        </Button>
      </div>
      <div className="table-action"></div>
      <Table
        rowKey={"id"}
        columns={columns}
        dataSource={dataSource}
        pagination={{
          pageSize: pagination.pageSize,
          total: pagination.total,
        }}
        onChange={paginationChange}
      />

      {/* 创建 */}
      {visibleCreate && (
        <CreateShopForm
          visible={visibleCreate}
          info={visibleInfo}
          onCreate={onCreate}
          onCancel={() => {
            setVisibleCreate(false);
            setVisibleInfo({});
          }}
        />
      )}
    </div>
  );
};

export default List;
