import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
import qs from "qs";

/**
 * 获取反馈列表
 * @param {Object} parm 请求参数
 * @param {Number} parm.pageSize 每页数量
 * @param {Number} parm.pageNo 当前页码
 * @returns
 */
export const GET_FEEDBACK_LIST = async (parm) =>
  await instance.post(`${base}/admin/feedback/list`, parm);

/**
 * 获取反馈详情
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 等级项ID
 * @returns
 */
export const GET_FEEDBACK_DETAIL = async () =>
  await instance.get(`${base}/admin/feedback/detail`);

/**
 * 设置反馈
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 反馈项ID
 * @param {String} parm.title 反馈标题
 * @param {String} parm.content 反馈内容
 * @param {String} parm.create_user 反馈用户
 * @param {Number} parm.state 状态 0:待处理 1:已接收 2:已解决 3:无法解决
 * @returns
 */
export const SET_FEEDBACK = async (parm) =>
  await instance.post(`${base}/admin/feedback/set`, parm);

/**
 * 删除反馈
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 等级项ID
 * @returns
 */
export const DEL_FEEDBACK = async (parm) =>
  await instance.delete(`${base}/admin/feedback/del`, { data: { ...parm } });
