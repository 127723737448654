import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
import qs from "qs";

/**
 * 获取版本列表
 * @param {Object} parm 请求参数
 * @param {Number} parm.pageSize 每页数量
 * @param {Number} parm.pageNo 当前页码
 * @returns
 */
export const GET_VERSION_LIST = async (parm) =>
  await instance.post(`${base}/admin/version/list`, parm);

/**
 * 获取版本详情
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 等级项ID
 * @returns
 */
export const GET_VERSION_DETAIL = async () =>
  await instance.get(`${base}/admin/version/detail`);

/**
 * 设置版本
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 版本项ID
 * @param {String} parm.versionNo 版本号
 * @param {String} parm.versionDesc 版本描述
 * @param {String} parm.url 版本地址
 * @param {String} parm.system 系统
 * @param {String} parm.isFullPackage 是否全量包 0:否 1:是
 * @returns
 */
export const SET_VERSION = async (parm) =>
  await instance.post(`${base}/admin/version/set`, parm);

/**
 * 删除版本
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 等级项ID
 * @returns
 */
export const DEL_VERSION = async (parm) =>
  await instance.delete(`${base}/admin/version/del`, { data: { ...parm } });

/**
 * 获取版本产品列表
 * @param {Object} parm 请求参数
 * @param {Number} parm.pageSize 每页数量
 * @param {Number} parm.pageNo 当前页码
 * @returns
 */
export const GET_VERSION_PRODUCT_LIST = async (parm) =>
  await instance.post(`${base}/admin/version/product/list`, parm);

/**
 * 获取版本产品详情
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 等级项ID
 * @returns
 */
export const GET_VERSION_PRODUCT_DETAIL = async () =>
  await instance.get(`${base}/admin/version/product/detail`);

/**
 * 设置版本产品
 * @param {Object} parm 请求参数
 * @param {Number} id 版本产品ID（修改用）
 * @param {String} name 版本产品名称
 * @param {Number} sort 版本产品排序
 * @returns
 */
export const SET_VERSION_PRODUCT = async (parm) =>
  await instance.post(`${base}/admin/version/product/set`, parm);

/**
 * 删除版本产品
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 等级项ID
 * @returns
 */
export const DEL_VERSION_PRODUCT = async (parm) =>
  await instance.delete(`${base}/admin/version/product/del`, {
    data: { ...parm },
  });

/**
 * 获取版本型号列表
 * @param {Object} parm 请求参数
 * @param {Number} parm.pageSize 每页数量
 * @param {Number} parm.pageNo 当前页码
 * @returns
 */
export const GET_VERSION_MODEL_LIST = async (parm) =>
  await instance.post(`${base}/admin/version/model/list`, parm);

/**
 * 获取版本型号详情
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 等级项ID
 * @returns
 */
export const GET_VERSION_MODEL_DETAIL = async () =>
  await instance.get(`${base}/admin/version/model/detail`);

/**
 * 设置版本型号
 * @param {Object} parm 请求参数
 * @param {Number} id 版本型号ID（修改用）
 * @param {Number} product_id 所属产品id
 * @param {String} name 版本型号名称
 * @param {Number} sort 版本型号排序
 * @param {String} docs 囊括文档id 多选 以 , 隔开
 * @returns
 */
export const SET_VERSION_MODEL = async (parm) =>
  await instance.post(`${base}/admin/version/model/set`, parm);

/**
 * 删除版本型号
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 等级项ID
 * @returns
 */
export const DEL_VERSION_MODEL = async (parm) =>
  await instance.delete(`${base}/admin/version/model/del`, {
    data: { ...parm },
  });

/**
 * 获取版本引导列表
 * @param {Object} parm 请求参数
 * @param {Number} parm.pageSize 每页数量
 * @param {Number} parm.pageNo 当前页码
 * @returns
 */
export const GET_VERSION_GUIDE_LIST = async (parm) =>
  await instance.post(`${base}/admin/version/guide/list`, parm);

/**
 * 获取版本引导详情
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 等级项ID
 * @returns
 */
export const GET_VERSION_GUIDE_DETAIL = async () =>
  await instance.get(`${base}/admin/version/guide/detail`);

/**
 * 设置版本引导
 * @param {Object} parm 请求参数
 * @param {Number} id 版本引导ID（修改用）
 * @param {Number} model_id 所属版本型号ID
 * @param {String} name 版本号
 * @param {String} intro 版本引导描述
 * @param {String} logs 版本引导记录
 * @param {String} url 下载地址
 * @param {Number} state 状态 0:隐藏 1:显示
 * @returns
 */
export const SET_VERSION_GUIDE = async (parm) =>
  await instance.post(`${base}/admin/version/guide/set`, parm);

/**
 * 删除版本引导
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 等级项ID
 * @returns
 */
export const DEL_VERSION_GUIDE = async (parm) =>
  await instance.delete(`${base}/admin/version/guide/del`, {
    data: { ...parm },
  });

/**
 * 获取版本镜像列表
 * @param {Object} parm 请求参数
 * @param {Number} parm.pageSize 每页数量
 * @param {Number} parm.pageNo 当前页码
 * @returns
 */
export const GET_VERSION_IMAGE_LIST = async (parm) =>
  await instance.post(`${base}/admin/version/image/list`, parm);

/**
 * 获取版本镜像详情
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 等级项ID
 * @returns
 */
export const GET_VERSION_IMAGE_DETAIL = async () =>
  await instance.get(`${base}/admin/version/image/detail`);

/**
 * 设置版本镜像
 * @param {Object} parm 请求参数
 * @param {Number} id 版本镜像ID（修改用）
 * @param {Number} model_id 所属版本型号ID
 * @param {String} name 版本号
 * @param {String} intro 版本镜像描述
 * @param {String} logs 版本镜像记录
 * @param {String} url 下载地址
 * @param {Number} prev_version_id 上一个版本的id
 * @param {Number} state 状态 0:隐藏 1:显示
 * @returns
 */
export const SET_VERSION_IMAGE = async (parm) =>
  await instance.post(`${base}/admin/version/image/set`, parm);

/**
 * 删除版本镜像
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 等级项ID
 * @returns
 */
export const DEL_VERSION_IMAGE = async (parm) =>
  await instance.delete(`${base}/admin/version/image/del`, {
    data: { ...parm },
  });

/**
 * 获取版本文档列表
 * @param {Object} parm 请求参数
 * @param {Number} parm.pageSize 每页数量
 * @param {Number} parm.pageNo 当前页码
 * @returns
 */
export const GET_VERSION_DOCS_LIST = async (parm) =>
  await instance.post(`${base}/admin/version/docs/list`, parm);

/**
 * 获取版本文档详情
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 等级项ID
 * @returns
 */
export const GET_VERSION_DOCS_DETAIL = async () =>
  await instance.get(`${base}/admin/version/docs/detail`);

/**
 * 设置版本文档
 * @param {Object} parm 请求参数
 * @param {Number} id 版本文档ID（修改用）
 * @param {String} name 版本文档名称
 * @param {Number} type 版本文档类型 1:应用 2:客户端 3:驱动 4:文档
 * @param {String} intro 版本文档描述
 * @param {String} url 下载地址
 * @param {String} md5 MD5
 * @param {Number} state 状态 0:隐藏 1:显示
 * @returns
 */
export const SET_VERSION_DOCS = async (parm) =>
  await instance.post(`${base}/admin/version/docs/set`, parm);

/**
 * 删除版本文档
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 等级项ID
 * @returns
 */
export const DEL_VERSION_DOCS = async (parm) =>
  await instance.delete(`${base}/admin/version/docs/del`, {
    data: { ...parm },
  });
